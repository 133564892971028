import React from 'react';

import { IPermission, ITemplate } from '@models/rich-content';
import RichContentHighlights from '../../+page/RichContentHighlights';
import RichContentImageGrid from '../../+page/RichContentImageGrid';
import RichContentImageSection from '../../+page/RichContentImageSection';
import RichContentImageTextUploader from '../../+page/RichContentImageTextUploader';
import RichContentMediaCarousel from '../../+page/RichContentMediaCarousel';
import RichContentTestimonials from '../../+page/RichContentTestimonials';
import RichContentThreeReview from '../../+page/RichContentThreeReview';
import RichContentVideoUploader from '../../+page/RichContentVideoUploader';
import RichContentVideoUploaderWithTitle from '../../+page/RichContentVideoUploaderWithTitle';
import RichContentVideoUploaderWithTitleAndDesc from '../../+page/RichContentVideoUploaderWithTitleAndDesc';

interface ISectionFactory {
  permissions: IPermission;
  sectionOrder: number;
  section: ITemplate;
  totalSections: number;
  moveSection: Function;
  handleChange: Function;
}

const SectionFactory: React.FC<ISectionFactory> = ({
  permissions,
  sectionOrder,
  section,
  totalSections,
  moveSection,
  handleChange,
}) => {
  const props = {
    index: sectionOrder,
    length: totalSections,
    moveSection,
    permissions,
    attributes: section.attributes,
    handleChange,
  };

  const SectionsMap: { [key: string]: JSX.Element } = {
    imageText: <RichContentImageTextUploader alignment="right" {...props} />,
    textImage: <RichContentImageTextUploader {...props} />,
    testimonial: <RichContentTestimonials {...props} />,
    mediaCarousel: <RichContentMediaCarousel {...props} />,
    video: <RichContentVideoUploader {...props} />,
    videoWithTitle: <RichContentVideoUploaderWithTitle {...props} />,
    videoWithTitleAndDesc: (
      <RichContentVideoUploaderWithTitleAndDesc {...props} />
    ),
    threeReviews: <RichContentThreeReview {...props} />,
    imageSection: <RichContentImageSection {...props} />,
    highlights: <RichContentHighlights {...props} />,
    pilotReview: <RichContentImageGrid {...props} />,
  };
  return SectionsMap[section.sectionName] || null;
};

export default SectionFactory;
