import React from 'react';
import { useTranslation } from 'react-i18next';

import SectionHeader from '../RichContentSectionHeader';
import AssetGallery from '@components/AssetGallery';

const MediaCarousel: React.FC<any> = ({
  moveSection,
  index,
  length,
  permissions,
  handleChange,
  images,
}) => {
  const { t } = useTranslation();

  const setImages = (val: any) => {
    handleChange(JSON.stringify(val), 'images', index);
  };

  return (
    <>
      <div
        className={`${
          permissions.VISUAL_ASSETS ? '' : 'RichContentTemplate__readOnly'
        }`}
      >
        <SectionHeader
          title={t('template.media_carousel')}
          moveSection={moveSection}
          showDropdown={permissions.VISUAL_ASSETS}
          index={index}
          length={length}
        />
        <AssetGallery
          galleryDescription={''}
          galleryTitle={''}
          images={images}
          editable={permissions.VISUAL_ASSETS}
          maxLimit={7}
          setImages={setImages}
          fullyEnabled={true}
        />
      </div>
    </>
  );
};
export default React.memo(MediaCarousel);
