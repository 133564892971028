import { GET_CURRENCY_ONBOARD_DATE } from '@apis/graphql/dashboard';
import { clientWithToken, BASE } from '.';

export const fetchTenantLogo = async (realm: string) => {
  const { data } = await clientWithToken.get<{ logoUrl: string }>(
    `/tenants/tenants/${realm}/public-data/logo`,
  );
  return data.logoUrl;
};

export type ProductGuide = {
  [key: string]: {
    steps: {
      stepIndex: number;
      title: string;
      content: string;
      target: string;
      disableBeacon: boolean;
    }[];
  };
};

export const fetchProductGuide = async (): Promise<ProductGuide> => {
  const GUIDE_URL =
    'https://static.heuristiccommerce.com/product-guide/guide.json';

  const response = await fetch(GUIDE_URL);
  if (response.status !== 200) throw response;
  else return response.json();
};
