import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import ModalLayout from '@components/ModalLayout';
import PrimaryButton from '@components/PrimaryButton';

import { capitalizeEveryFirstLetter } from '@utils/textTransform';

import ImageTextSelection from '@assets/illustrations/image_text.svg';
import TextImageSelection from '@assets/illustrations/text_image.svg';
import TestimonialSelection from '@assets/illustrations/testimonial_selection.svg';
import VideoCardSelection from '@assets/illustrations/video_selection.svg';
import ImageSection from '@assets/illustrations/ImageSection.svg';
import ThreeReview from '@assets/illustrations/ThreeReview.svg';
import ImageGrid from '@assets/illustrations/ImageGrid.svg';

import './style.scss';

const AddSectionModal: React.FC<any> = ({
  showModal,
  setShowModal,
  onClose,
  addNewSection,
}) => {
  const { t } = useTranslation();
  const [hostname, setHostname] = useState('');

  useEffect(() => {
    const { hostname } = window.location;
    setHostname(hostname);
  }, []);
  const defaultData = [
    {
      image: TextImageSelection,
      buttonText: t('rich_content.text_and_image'),
      action: 'textImage',
    },
    {
      image: ImageTextSelection,
      buttonText: t('rich_content.image_and_text'),
      action: 'imageText',
    },
    // {
    //   image: ImageCardSelection,
    //   buttonText: t('rich_content.media_carousel'),
    //   action: 'mediaCarousel',
    // },
    {
      image: VideoCardSelection,
      buttonText: t('global.video'),
      action: 'video',
    },
    {
      image: VideoCardSelection,
      buttonText: t('global.videoWithTitle'),
      action: 'videoWithTitle',
    },
    {
      image: VideoCardSelection,
      buttonText: t('global.videoWithTitleAndDesc'),
      action: 'videoWithTitleAndDesc',
    },
    {
      image: TestimonialSelection,
      buttonText: t('rich_content.testimonials'),
      action: 'testimonial',
    },
    {
      image: ImageSection,
      buttonText: t('rich_content.image_section'),
      action: 'imageSection',
      // displaySection:
      //   hostname.includes('drivereasy') || hostname.includes('littlehipstar'),
    },
    {
      image: ThreeReview,
      buttonText: t('rich_content.reviews'),
      action: 'threeReviews',
      // displaySection:
      //   hostname.includes('drivereasy') || hostname.includes('littlehipstar'),
    },
    {
      image: ThreeReview,
      buttonText: t('taskMaster.highlights'),
      action: 'highlights',
      // displaySection: hostname.includes('armstrong'),
    },
    {
      image: ImageGrid,
      buttonText: t('rich_content.image_grid'),
      action: 'imageGrid',
      // displaySection: hostname.includes('drivereasy'),
    },
  ];

  return (
    <>
      <ModalLayout
        showModal={showModal}
        setShowModal={setShowModal}
        onClose={onClose}
        defaultClose
        className="RichContentAddSelectionModal"
      >
        <h3>{t('rich_content.section_selection_heading')}</h3>
        <p>{t('rich_content.section_selection_sub_text')}</p>
        <div>
          {defaultData.map(({ image, buttonText, action }, index) => (
            <div key={index}>
              <img src={image} alt="add icon" />
              <PrimaryButton
                onClick={() => addNewSection(action)}
                label={capitalizeEveryFirstLetter(buttonText)}
              />
            </div>
          ))}
        </div>
      </ModalLayout>
    </>
  );
};
export default AddSectionModal;
