import { registerSideEffects } from '@redux/sideEffects';
import { createSlice } from '@reduxjs/toolkit';
import { AppThunk } from '@redux/store';
import { PrimaryButtonVariant } from '@components/PrimaryButton';

type BaseModal = {
  type: string;
  heading: string;
  supportingText: string;
};

// TODO Remove
/** @deprecated */
type ModalA = BaseModal & {
  type: 'typeA';
  icon?: JSX.Element;
  children?: null | JSX.Element | JSX.Element[];
};

/** @deprecated */
type ModalB = BaseModal & {
  type: 'typeB';
  icon?: JSX.Element;
  buttons: {
    text: string;
    variant: PrimaryButtonVariant;
    method: () => void;
  }[];
  className?: string;
  danger: boolean;
  children?: null | JSX.Element | JSX.Element[];
};

/**
 * ! IMPORTANT ! Only use if existing types don't work and use-case is too unique to create new type
 *
 * Allows you to create fully unique modals.
 */
type CustomModal = {
  type: 'custom';
  children: JSX.Element | JSX.Element[];
};

export type Modal = ModalA | ModalB | CustomModal;

type ModalState = {
  /** The modal that is currently open */
  current: Modal | null;
};

const initialState: DeepReadonly<ModalState> = {
  current: null,
};

const { actions, reducer } = createSlice({
  name: 'modal',
  initialState,
  reducers: {
    open: (state, { payload }: { payload: Modal }) => {
      state.current = payload;
    },
    close: (state) => {
      state.current = null;
    },
  },
});

const thunks = {} satisfies { [key: string]: (...args: any[]) => AppThunk };

registerSideEffects();

export const RModal = Object.assign(actions, thunks);

export default reducer;
