import { AllFilesType } from '@models/files';

export const ASSET_TYPES = [
  { extension: 'jpeg', mime: 'image/jpeg', type: 'image' },
  { extension: 'jpg', mime: 'image/jpg', type: 'image' },
  { extension: 'png', mime: 'image/png', type: 'image' },
  { extension: 'svg', mime: 'image/svg+xml', type: 'image' },
  { extension: 'gif', mime: 'image/gif', type: 'image' },
  { extension: 'pdf', mime: 'application/pdf', type: 'document' },
  { extension: 'mp4', mime: 'video/mp4', type: 'video' },
  { extension: 'mvp', mime: 'video/mvp', type: 'video' },
  { extension: 'mov', mime: 'video/quicktime', type: 'video' },
  { extension: 'vtt', mime: 'text/vtt', type: 'transcript' },
] as const satisfies ReadonlyArray<
  Readonly<{
    extension: string;
    mime: string;
    type: 'image' | 'video' | 'document' | 'transcript';
  }>
>;

export const ALL_EXTENSIONS = ASSET_TYPES.map((t) => t.extension);

export const NORMAL_IMAGES = ASSET_TYPES.filter(
  (a) =>
    a.extension === 'jpg' || a.extension === 'jpeg' || a.extension === 'png',
).map((a) => a.mime);

export const ALL_IMAGES = ASSET_TYPES.filter((a) => a.type === 'image').map(
  (a) => a.mime,
);

export const ALL_VIDEOS = ASSET_TYPES.filter((a) => a.type === 'video').map(
  (a) => a.mime,
);

export const ALL_EXTENSIONS_TO_TYPES_MAP = Object.fromEntries(
  ASSET_TYPES.map((t) => [t.extension, t.mime]),
) as {
  [ext in (typeof ASSET_TYPES)[number]['extension']]: Extract<
    (typeof ASSET_TYPES)[number],
    { extension: ext }
  >['mime'];
};

export const ALL_TYPES_TO_EXTENSIONS_MAP = Object.fromEntries(
  ASSET_TYPES.map((t) => [t.mime, t.extension]),
) as {
  [mime in (typeof ASSET_TYPES)[number]['mime']]: Extract<
    (typeof ASSET_TYPES)[number],
    { mime: mime }
  >['extension'];
};

export const ALL_EXTENSION_TYPES: AllFilesType[] = ASSET_TYPES.filter(
  (a) => a.type === 'image',
).map((a) => a.mime);
