import React from 'react';

export const UserIcon = ({
  height = '24px',
  width = '24px',
  fill = 'var(--oxford-blue-shade-5)',
  ...props
}: React.SVGProps<SVGSVGElement>) => (
  <svg
    version="1.1"
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox="0 0 200 200"
    {...props}
  >
    <g id="User" transform="translate(0.668 0.5)">
      <path
        fill={fill}
        d="M98.5,133c-14.6,0-25.9-4.5-33.8-13.3c-14.3-16.2-11.2-40.9-11-41.9c0.3-2.6,2.7-4.3,5.3-4
		c2.6,0.3,4.3,2.7,4,5.2c0,0.2-2.7,21.6,8.8,34.5c6.1,6.9,15.5,10.2,27.6,10.1c11.6-0.2,20.1-3.3,26-9.5
		c10.8-11.2,11.1-31.2,10.7-45.9c-0.7,0.4-1.6,0.7-2.5,0.7c-5.1,0-10.3-4-15.3-12c-21.9,13.9-54.3,14-69.6,5.5
		c-7.2-4-8.8-8.8-8.8-12.2C40,34.2,62.2,3.4,92.6,3.4c16-1.2,31.2,7.8,38,22.7c10.1,7.2,15.6,19.2,14.6,31.6c0,1.7,0.1,4.1,0.2,6.7
		c0.5,16.4,1.2,41.2-13.2,56.3c-7.7,8-18.7,12.2-32.7,12.4C99.1,133,98.8,133,98.5,133z M118.4,47.7c0.6,0,1.2,0.1,1.8,0.2
		c2.4,0.5,4.6,1.9,6,4c4,6.4,6.8,7.8,7.5,7.8c0.8,0,1.6,0.2,2.3,0.6c0-1,0-2,0-3c0.8-9.9-3.6-19-11.6-24.2l-1.2-0.8l-0.5-1.3
		c-4.9-11.9-16.8-19.1-29.6-18.2c-25.3,0-43.6,26.4-43.6,37.6c0,1.1,1.5,2.7,3.9,4c11.6,6.4,40,7.5,60-5.1
		C114.8,48.3,116.5,47.7,118.4,47.7z"
      />
      <path
        fill={fill}
        d="M167.8,195.7c-1.7,0-3.4-1-4.2-2.6c-5.8-11.9-14.8-22-25.9-29.1c-23.3-15.3-53.4-15.3-76.7,0
		c-11.2,7.2-20.2,17.2-26,29.1c-1.1,2.3-3.9,3.3-6.2,2.2c-2.3-1.1-3.3-3.9-2.2-6.3c6.5-13.5,16.7-24.8,29.3-32.9
		c26.4-17.3,60.5-17.3,86.8,0c12.6,8,22.7,19.4,29.2,32.9c1.1,2.3,0.2,5.1-2.2,6.2C169.2,195.6,168.5,195.7,167.8,195.7z"
      />
    </g>
  </svg>
);
