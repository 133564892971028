import { Image } from '@components/AssetGallery';
import { DigitalAssetBrief } from '@models/digital-asset';
import { AssetType } from '@models/productVersion';

export const getAssetType = (url: string): AssetType => {
  const imagesTypes = ['jpeg', 'jpg', 'png', 'svg', 'gif'];
  const videoTypes = ['mp4', 'mvp', 'mov'];
  let type: AssetType;
  const extentsion = getUrlExtension(url);
  if (imagesTypes.includes(extentsion)) type = 'image';
  else if (videoTypes.includes(extentsion)) type = 'video';
  else type = 'doc';
  return type;
};

/** @description returns images in sorted order */
export const sortedImages = (images: Image[]): Image[] => {
  const sortedImages = images.sort((a, b) => a.position - b.position);
  return sortedImages;
};

/** @description map images to original positions */
export const mapToOriginalPosition = (data: Image[]): Image[] => {
  return data.map((asset, index) => ({
    ...asset,
    id: `${index}`,
    position: index + 1,
  }));
};

/** @description filter the images to be removed */
export const deleteAsset = (
  position: number | undefined | null,
  images: Image[],
) => {
  let finalImages = [...images];
  finalImages = finalImages.filter((img) => img.position !== position);
  finalImages = mapToOriginalPosition(finalImages);
  return finalImages;
};

/** @description uploads image to DAM and then then return final images */
export const getFinalImagesAfterUpload = (
  images: Image[],
  selectedImagesFromModal: DeepReadonly<DigitalAssetBrief[]>,
  newImageToSwap: Image | null | undefined,
) => {
  // Initialize Images
  let finalImages = [...images];

  // Prepare objects for new Images
  const newImages = selectedImagesFromModal.map((newImage, index) => ({
    id: `${
      newImageToSwap
        ? newImageToSwap.position + index - 1
        : images.length + index
    }`,
    url: newImage.url,
    assetId: newImage.id,
    position: newImageToSwap
      ? newImageToSwap.position + index
      : images.length + index + 1,
    altText: newImage.altText,
    type: getAssetType(newImage.url),
    assetTitle: newImage.title,
  }));

  // If picture is uploaded from between
  // Else using Add Icon
  if (newImageToSwap) {
    const index = finalImages.findIndex(
      (img) => newImageToSwap.position === img.position,
    );
    finalImages.splice(index, 0, ...newImages);
    // Map images to original index and position
    finalImages = mapToOriginalPosition(finalImages);
  } else finalImages.push(...newImages);
  return finalImages;
};

export const getUrlExtension = <T extends string | undefined>(url: T): T =>
  url?.split(/[#?]/)[0].split('.').pop()?.trim() as T;

export const isValidUrl = (url: string): boolean => {
  try {
    new URL(url);
    return true;
  } catch (err) {
    return false;
  }
};
