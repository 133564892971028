import i18n from 'i18next';
import { ITemplateAttribute } from '@models/rich-content';
const stepsData = (selectedType: any, t: any) => [
  {
    heading: `${t('global.select')} ${selectedType}`,
    subText: `${t('global.choose')} ${t('global.the')} ${selectedType}(s) ${t(
      'rich_content.you_wish_to_create_rich_content_for',
    )}`,
  },
  {
    heading: `${t('productDashboard.setTask')}`,
    subText: `${t(
      'rich_content.give_the_rich_content_version_a_title_and_create_a_brief_outlining_what_you_need_from_your_team',
    )}`,
  },
  {
    heading: `${t('rich_content.assign_tasks')}`,
    subText: `${t(
      'rich_content.select_the_team_members_responsible_for_each_deliverable_to_be_notified',
    )}`,
  },
];

const reviewDefaultValues = (index: string) => {
  return {
    [`name${index}`]: {
      label: i18n.t('taskMaster.customer_name'),
      attributeType: 'TextInput',
      standardText: '',
      enrichedText: [],
    },
    [`image${index}`]: {
      label: i18n.t('global.image'),
      attributeType: 'ImageUrl',
      standardText: '',
      enrichedText: [],
    },
    [`review${index}`]: {
      label: i18n.t('rich_content.testimonial_content'),
      attributeType: 'TextInput',
      standardText: '',
      enrichedText: [],
    },
  };
};

const highlightDefaultValues = (index: string) => {
  return {
    [`highlight${index}`]: {
      label: i18n.t('global.title'),
      attributeType: 'TextInput',
      standardText: '',
      enrichedText: [],
    },
    [`image${index}`]: {
      label: i18n.t('global.image'),
      attributeType: 'ImageUrl',
      standardText: '',
      enrichedText: [],
    },
    [`description${index}`]: {
      label: i18n.t('taskMaster.highlights'),
      attributeType: 'TextInput',
      standardText: '',
      enrichedText: [],
    },
  };
};

const defaultValues: ITemplateAttribute = {
  heading: {
    label: i18n.t('global.heading'),
    attributeType: 'TextInput',
    standardText: '',
    enrichedText: [],
  },
  description: {
    label: i18n.t('context.description'),
    attributeType: 'TextInput',
    standardText: '',
    enrichedText: [],
  },
  image: {
    label: i18n.t('global.image'),
    attributeType: 'ImageUrl',
    standardText: '',
    enrichedText: [],
    assetIds: [],
  },
  callActionBtnText: {
    label: i18n.t('rich_content.button_text'),
    attributeType: 'TextInput',
    standardText: '',
    enrichedText: [],
  },
  callActionBtnURL: {
    label: i18n.t('taskMaster.url'),
    attributeType: 'TextInput',
    standardText: '',
    enrichedText: [],
  },
};

const imageTextSection = {
  sectionName: 'imageText',
  id: 'imageText',
  order: 0,
  rawContent: '24345082',
  attributes: { ...defaultValues },
};

const textImageSection = {
  sectionName: 'textImage',
  id: 'textImage',
  order: 0,
  rawContent: '24345082',
  attributes: { ...defaultValues },
};
const carouselSection = {
  sectionName: 'mediaCarousel',
  id: 'mediaCarousel',
  order: 0,
  rawContent: '24345082',
  attributes: {
    images: {
      label: i18n.t('global.image'),
      attributeType: 'ImageUrl',
      standardText: '',
      enrichedText: [],
    },
  },
};
const testimonialsSection = {
  sectionName: 'testimonial',
  id: 'testimonial',
  order: 0,
  rawContent: '24345082',
  attributes: {
    testimonialText: {
      label: i18n.t('global.content'),
      attributeType: 'TextInput',
      standardText: '',
      enrichedText: [],
    },
    testimonialAuthor: {
      label: i18n.t('global.attribution'),
      attributeType: 'TextInput',
      standardText: '',
      enrichedText: [],
    },
  },
};
const videoSection = {
  sectionName: 'video',
  id: 'video',
  order: 0,
  rawContent: '24345082',
  attributes: {
    video: {
      label: i18n.t('global.video'),
      attributeType: 'VideoUrl',
      standardText: '',
      enrichedText: [],
      assetIds: [],
    },
  },
};

const videoWithTitleSection = {
  sectionName: 'videoWithTitle',
  id: 'videoWithTitle',
  order: 0,
  rawContent: '24345082',
  attributes: {
    video: {
      label: i18n.t('global.videoWithTitle'),
      attributeType: 'VideoUrl',
      standardText: '',
      enrichedText: [],
      assetIds: [],
    },
    heading: {
      label: i18n.t('global.heading'),
      attributeType: 'TextInput',
      standardText: '',
      enrichedText: [],
      assetIds: [],
    },
  },
};

const videoWithTitleAndDescSection = {
  sectionName: 'videoWithTitleAndDesc',
  id: 'videoWithTitleAndDesc',
  order: 0,
  rawContent: '24345082',
  attributes: {
    video: {
      label: i18n.t('global.videoWithTitleAndDesc'),
      attributeType: 'VideoUrl',
      standardText: '',
      enrichedText: [],
      assetIds: [],
    },
    heading: {
      label: i18n.t('global.heading'),
      attributeType: 'TextInput',
      standardText: '',
      enrichedText: [],
      assetIds: [],
    },
    description: {
      label: i18n.t('global.sub_heading'),
      attributeType: 'TextInput',
      standardText: '',
      enrichedText: [],
      assetIds: [],
    },
  },
};

const imageSection = {
  sectionName: 'imageSection',
  id: 'imageSection',
  order: 0,
  rawContent: '24345082',
  attributes: {
    heading: { ...defaultValues['heading'] },
    subHeading: {
      label: i18n.t('global.sub_heading'),
      attributeType: 'TextInput',
      standardText: '',
      enrichedText: [],
    },
    notes: {
      label: i18n.t('global.notes'),
      attributeType: 'TextInput',
      standardText: '',
      enrichedText: [],
    },
    image: { ...defaultValues['image'] },
  },
};

const reviewSection = {
  sectionName: 'threeReviews',
  id: 'threeReviews',
  order: 0,
  rawContent: '24345082',
  attributes: {
    title: {
      label: i18n.t('global.title'),
      attributeType: 'TextInput',
      standardText: '',
      enrichedText: [],
    },
    ...reviewDefaultValues('1'),
    ...reviewDefaultValues('2'),
    ...reviewDefaultValues('3'),
  },
};

const highlightSection = {
  sectionName: 'highlights',
  id: 'highlights',
  order: 0,
  rawContent: '24345082',
  attributes: {
    ...highlightDefaultValues('1'),
    ...highlightDefaultValues('2'),
    ...highlightDefaultValues('3'),
  },
};

const imageGridSection = {
  sectionName: 'pilotReview',
  id: 'pilotReview',
  order: 0,
  rawContent: '24345082',
  attributes: {
    heading: defaultValues['heading'],
    img1: defaultValues['image'],
    img2: defaultValues['image'],
    img3: defaultValues['image'],
    img4: defaultValues['image'],
    img5: defaultValues['image'],
    img6: defaultValues['image'],
  },
};

export {
  stepsData,
  imageTextSection,
  textImageSection,
  testimonialsSection,
  carouselSection,
  videoSection,
  videoWithTitleSection,
  videoWithTitleAndDescSection,
  reviewSection,
  imageSection,
  highlightSection,
  imageGridSection,
};
