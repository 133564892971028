import moment from 'moment';
import i18next from 'i18next';
import {
  VersionTargetingOption,
  DUE_TYPE,
  CREATE_VERSION_STEP,
  FieldName,
  CREATION_TYPE,
} from '@constants/productVersion';
import { LegacyProductVersion, LegacyVersion } from '@models/productVersion';
import { RProductVersion } from '@redux/slices/productVersion';
import store from '@redux/store';
import {
  checkProductVersionExists,
  updatePrescriptiveSettings,
} from '@apis/productVersions';
import { checkIsAttributeInputValid, toContentList } from './productTemplate';
import { capitalizeFirstLetter } from './textTransform';
import { Mixpanel } from './mixpanel';

import { toast } from '@components/ToastNotification/ToastManager';
import { DefaultRootState } from 'react-redux';
import { AssetCache } from '@components/ContentEditor';

const checkMinLength = (value: number, min: number) => value >= min;

/**
 * @param version
 * @returns Validates STEP - 1 (Overview) payload
 */
const validateOverviewPayload = (version: LegacyVersion.PostVersion) => {
  let isValid = true;
  let error = '';
  const { audienceType, contextIds } = version;
  if (audienceType === VersionTargetingOption.context) {
    isValid = checkMinLength(contextIds.length, 1);
    if (!isValid) {
      error = 'Please select at least one Context';
      return { isValid, error };
    }
  }
  return {
    isValid,
    error,
  };
};

const isDateUpdatedByUser = (startDate: number, endDate?: number) => {
  const version = store.getState().productVersion.version;
  if (!version) return {};

  const { publishDate, expiryDate } = version;
  return {
    isStartDateUpdated: publishDate !== startDate,
    isEndDateUpdated: expiryDate !== endDate,
  };
};

/**
 * @param version
 * @returns Validates STEP - 2 (Publishing) payload
 */
const validatePublishingPayload = (version: LegacyVersion.UpdateBody) => {
  let isValid = true;
  let error = '';
  const { publishDate, expiryDate, dueType } = version;
  if (publishDate && dueType === DUE_TYPE.onAdate) {
    const dateUpdated = isDateUpdatedByUser(publishDate, expiryDate);
    if (
      publishDate < moment().startOf('day').valueOf() &&
      dateUpdated.isStartDateUpdated
    ) {
      error = 'Publish date can not be less than current date';
      isValid = false;
      return { isValid, error };
    }
    if (expiryDate && dateUpdated.isEndDateUpdated) {
      if (expiryDate < moment().startOf('day').valueOf()) {
        error = 'End date can not be less than current date';
        isValid = false;
        return { isValid, error };
      }

      if (publishDate > expiryDate) {
        error = 'Start date can not be greater than end date';
        isValid = false;
        return { isValid, error };
      }
    }
  }
  return {
    isValid,
    error,
  };
};

/**
 * @param version
 * @returns Validates STEP - 3 (Content) payload
 */
const validateContentPayload = (version: LegacyVersion.UpdateBody) => {
  let isValid = true;
  let error = '';
  const { contentReference } = version;
  isValid = validateContentList();
  if (!isValid) {
    error = i18next.t('product_version.error_in_fields');
  }
  if (!contentReference) {
    error = 'Please select content reference';
    isValid = false;
    return { isValid, error };
  }
  return {
    isValid,
    error,
  };
};

const validateContentList = (): boolean => {
  const { template, content } = store.getState().version;
  if (!template) return false;

  const templateValidation: { [key: string]: boolean } = {};

  for (const attribute of template.attributes) {
    const value = content[attribute.name];
    // We ignore as experience version can omit fields
    if (!value) continue;
    // If attribute has no validator it is automatically valid
    if (!attribute.validator) continue;

    const valid = checkIsAttributeInputValid(value, attribute, false);
    if (!valid) return false;
  }

  return Object.values(templateValidation)?.every(Boolean);
};

export const validateAudienceType = (
  audienceType: string,
  selectedContexts: DefaultRootState['contexts']['selectedContexts'],
): boolean =>
  audienceType === VersionTargetingOption.anonymous
    ? // Anonymous audience doesn't need further validation
      true
    : // Context audience requires at least one selected context
      !!Object.keys(selectedContexts).length;

/**
 * @param version
 * @returns Validates STEP - 2 (Publishing) payload
 */
export const createMetaDataPayload = (data: LegacyVersion.UpdateBody) => {
  const {
    title,
    description,
    audienceType,
    contextIds,
    isTestVersion,
    dueType,
    publishDate,
    expiryDate,
    controlGroupValue,
    contentReference,
    thumbnail,
    taskList,
    highPriority,
    workflowEnabled,
  } = data;
  return {
    title,
    description,
    audienceType,
    contextIds,
    isTestVersion,
    dueType,
    publishDate,
    expiryDate,
    controlGroupValue,
    completedSteps: CREATE_VERSION_STEP.overview,
    contentReference,
    thumbnail,
    workflowEnabled,
    taskList,
    highPriority,
  };
};

/**
 * @param version
 * @returns Validates STEP - 3 (Content) payload
 */

export const createContentPayload = async (
  data: any,
  assetCache: AssetCache,
): Promise<any> => {
  const { content, template } = store.getState().version;
  const { referenceVersion } = store.getState().version;

  const contentList = await toContentList(content, assetCache);

  return {
    ...data,
    [FieldName.completedSteps]: CREATE_VERSION_STEP.content,
    [FieldName.contentList]: contentList,
    [FieldName.thumbnail]: getVersionThumbnail(contentList) || data.thumbnail,
    [FieldName.contentReference]: referenceVersion?.id || '',
    templateId: template?.id || '',
  };
};

/**
 * @param productId
 * @param contextIds
 * @returns Method check whether product version exist or not for same context
 */
export const checkVersionExistWithSameContext = async (
  productId: string,
  contextIds: LegacyVersion['contextIds'],
): Promise<boolean> => {
  const response = await checkProductVersionExists(productId, contextIds);
  if (response.isSuccess) {
    if (response.productVersionExist) return true;
    else return false;
  }
  return false;
};

/**
 *
 * @param productId
 * @param payload
 * @param successCallback
 * @param failureCallback
 * @description Method to create product version
 */
export const createVersion = (
  productId: string,
  payload: LegacyVersion.PostVersion,
  creationType: CREATION_TYPE,
  successCallback?: Function,
  failureCallback?: Function,
): void => {
  store.dispatch(
    RProductVersion.createProductVerisonSaga({
      productId: productId,
      body: payload,
      creationType,
      successCallback,
      failureCallback,
    }),
  );
};

/**
 *
 * @param method (HTTP Method)
 * @param productId
 * @param versionId
 * @param payload
 * @param successCallback
 * @param failureCallback
 *  @description Method to update product version
 */
export const updateVersion = (
  method: 'PUT' | 'PATCH',
  productId: string,
  versionId: string,
  payload: LegacyVersion.UpdateBody,
  creationType: string,
  successCallback?: Function,
  failureCallback?: Function,
): void => {
  store.dispatch(
    RProductVersion.updateProductVersionSaga({
      method,
      productId,
      versionId,
      body: payload,
      creationType,
      successCallback,
      failureCallback,
    }),
  );
};

/**
 *
 * @param version
 * @returns Initial values for all fields
 */
export const createProductVersionInitalDataObj = (version: LegacyVersion) => {
  const initalData = {
    [FieldName.title]: version?.title ?? '',
    [FieldName.description]: version?.description ?? '',
    [FieldName.publishDate]: version?.publishDate,
    [FieldName.expiryDate]: version?.expiryDate,
    [FieldName.dueType]: version?.dueType ?? DUE_TYPE.asap,
    [FieldName.audienceType]:
      version?.audienceType ?? VersionTargetingOption.anonymous,
    [FieldName.controlGroupValue]: version?.controlGroupValue ?? 20,
    [FieldName.contextIds]: version?.contextIds ?? [],
    [FieldName.published]: version?.published ?? true,
    [FieldName.contentList]: version?.contentList ?? [],
    [FieldName.taskList]: version?.taskList ?? [],
    [FieldName.contentReference]: version?.contentReference ?? '',
    [FieldName.isTestVersion]: version?.isTestVersion ?? true,
    [FieldName.isActive]: version?.isActive ?? true,
    [FieldName.completedSteps]:
      version?.completedSteps ?? CREATE_VERSION_STEP.overview,
    [FieldName.thumbnail]: version?.thumbnail ?? '',
    [FieldName.workflowEnabled]: version?.workflowEnabled ?? false,
    [FieldName.highPriority]: version?.highPriority ?? false,
  };
  return initalData;
};

/**
 * @param payload
 * @param currentStep
 * @returns Method to validate all three step
 */
export const validatePayload = (
  payload: LegacyVersion.UpdateBody,
  currentStep: string,
): boolean => {
  let isValidPayload = true;
  let errorMessage = '';
  switch (currentStep) {
    case CREATE_VERSION_STEP.overview:
      {
        const { isValid, error } = validateOverviewPayload(payload);
        isValidPayload = isValid;
        errorMessage = error;
      }
      break;

    case CREATE_VERSION_STEP.publishing:
      {
        const { isValid, error } = validatePublishingPayload(payload);
        isValidPayload = isValid;
        errorMessage = error;
      }
      break;

    case CREATE_VERSION_STEP.content:
      {
        const { isValid, error } = validateContentPayload(payload);
        isValidPayload = isValid;
        errorMessage = error;
      }
      break;
  }
  if (errorMessage) showError(errorMessage);
  return isValidPayload;
};

export const showError = (message: string): void =>
  toast.show({
    message,
    error: true,
  });

export const showMessage = (message: string): void =>
  toast.show({
    message,
  });

const getVersionThumbnail = (
  contentList: LegacyVersion.PostPersonalizedSection[],
) => {
  for (const { personalised_content } of contentList) {
    const first = personalised_content[0];
    if (first && first.asset_id) return first.personalised_text;
  }
  return '';
};

export const toggleVersionActiveHandler = async (
  data: LegacyProductVersion,
  successCallback: (
    result: Extract<
      Awaited<ReturnType<typeof updatePrescriptiveSettings>>,
      { isSuccess: true }
    >,
  ) => any,
  failureCallback: (
    result: Extract<
      Awaited<ReturnType<typeof updatePrescriptiveSettings>>,
      { isSuccess: false }
    >,
  ) => any,
): Promise<void> => {
  const payload = {
    data: {
      versionRatioList: [
        {
          makeupId: data.id,
          productId: data.productId,
          isActive: !data.isActive,
        },
      ],
    },
    productId: data.productId,
  };

  const response = await updatePrescriptiveSettings(payload);
  if (response.isSuccess) {
    successCallback(response);
    toast.show({
      message: `${capitalizeFirstLetter(
        i18next.t('taskMaster.version'),
      )} ${i18next.t('createContextualizedVersion.turned', {
        value: data.isActive ? i18next.t('global.off') : i18next.t('global.on'),
      })}`,
    });
  } else {
    failureCallback(response);
  }
};

/**
 * @returns Returns date max of all due dates with adding one day to it
 */
export const getPublishDateIfWorkflowIsEnabled = (
  publishDate: number,
  taskList: LegacyVersion['taskList'],
): number => {
  let date = publishDate;
  const maxDueDateOfAllTask = Math.max(
    ...(taskList?.map((task) => (task && task.dueDate ? task.dueDate : 0)) ||
      []),
  );
  if (maxDueDateOfAllTask) {
    date = maxDueDateOfAllTask + 86400000;
  }
  return date;
};

export const sendMixpanelEvent = (
  currentStep: string,
  creationType: string,
  payload?: LegacyVersion.UpdateBody,
  versionId?: string,
): void => {
  switch (currentStep) {
    case CREATE_VERSION_STEP.overview:
      Mixpanel.track('version_operation', {
        eventName: 'Step 1 Session',
        kpi: 'Usage Grows',
        propertyType: 'event_property',
        versionId: '',
        titleLength: payload?.title.length,
        targeting: payload?.audienceType,
        exitEvent: creationType,
      });
      break;

    case CREATE_VERSION_STEP.publishing:
      Mixpanel.track('version_operation', {
        eventName: 'Step 2 Session',
        kpi: 'Usage Grows',
        propertyType: 'event_property',
        versionId: versionId,
        publishType: payload?.dueType,
        deliveryRatio: payload?.controlGroupValue,
        publishDate: payload?.publishDate,
        expiryDate: payload?.expiryDate,
        exitEvent: creationType,
      });
      break;

    case CREATE_VERSION_STEP.content:
      Mixpanel.track('version_operation', {
        eventName: 'Step 3 Session (Content)',
        kpi: 'Usage Grows',
        propertyType: 'event_property',
        versionId: versionId,
        FieldsEdited: payload?.contentList?.length,
        exitEvent: creationType,
      });
      break;

    default:
      break;
  }
};
