import { useTranslation } from 'react-i18next';

import ReadOnlyModal from '@components/ReadOnlyModal';

import fiveSecondCountdown from '@assets/illustrations/fiveSecondCountdown.gif';

import './style.scss';

const VersionPreviewModal = () => {
  const { t } = useTranslation();
  return (
    <ReadOnlyModal
      showModal
      setShowModal={() => null}
      showCloseIcon={false}
      bodyClass="VersionPreviewModal"
      modalBody={
        <>
          <img
            className="VersionPreviewModal-image"
            src={fiveSecondCountdown}
            alt="Preview will we generated soon"
          />
          <h5 className="VersionPreviewModal-title">
            {t('product_version.generating_preview')}
          </h5>
          <p className="VersionPreviewModal-desc">
            {t('product_version.generating_preview_statement')}
            <strong className="text-sizzling-red">
              {' '}
              {t('product_version.generating_preview_pop_up_blocker')}
            </strong>
          </p>
        </>
      }
    />
  );
};
export default VersionPreviewModal;
