import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { useEffect } from 'react';
import { Modal, RModal } from '@redux/slices/modal';
import ReactHtmlParser from 'html-react-parser';
import { CloseIcon } from '../Icons/CloseIcon';
import { AlertIcon } from '../Icons/AlertIcon';
import { useTranslation } from 'react-i18next';
import PrimaryButton from '../PrimaryButton';

import './style.scss';

import '../Modals/typeB.scss';
import '../Modals/typeA.scss';

const ModalComponents: {
  [type in Modal['type']]: (
    props: Extract<Modal, { type: type }>,
  ) => JSX.Element;
} = {
  typeA: (props) => {
    const { children } = props;
    const dispatch = useDispatch();

    const closeModalIconClick = () => {
      dispatch(RModal.close());
    };

    return (
      <div className="type-a-modal">
        <CloseIcon
          className="close-model-icon"
          onClick={closeModalIconClick}
          role="button"
        />
        {props.icon}
        <h4>{props.heading}</h4>
        <p>{ReactHtmlParser(props?.supportingText || '')}</p>
        {children}
      </div>
    );
  },
  typeB: (props) => {
    const { children } = props;
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const closeModalIconClick = () => {
      dispatch(RModal.close());
    };

    return (
      <div className={`type-b-modal ${props?.className || ''}`}>
        {props?.icon ? (
          { icon: props.icon }
        ) : (
          <AlertIcon
            fill={`var(${props?.danger ? '--sizzling-red' : '--emerald'})`}
          />
        )}
        <div className="modal-text-container">
          <h6>{props?.heading}</h6>
          <p>{props?.supportingText} </p>
        </div>
        {children}
        <div className="modal-buttons-container">
          {props?.buttons.map((button, index) => (
            <PrimaryButton
              label={button.text}
              size="small"
              variant={button.variant || 'gray'}
              onClick={button.method}
              key={index}
            />
          ))}
        </div>
      </div>
    );
  },
  custom: (props) => <>{props.children}</>,
};

const Modals: React.FC = () => {
  const dispatch = useDispatch();
  const { current } = useSelector((state) => state.modals);
  const location = useLocation();

  useEffect(() => {
    // If the user navigates somewhere else close the modal
    if (location && current) {
      dispatch(RModal.close());
    }
  }, [location]);

  // If no modal is open or modalElement not found, return
  if (!current) return null;

  // TODO better ts type
  const ModalComponent = ModalComponents[current.type] as any;

  return (
    <div id="full-screen-model-with-redux">
      <div className="modal-with-redux" id="modal-with-redux-backdrop">
        <ModalComponent {...current} />
      </div>
    </div>
  );
};
export default Modals;
