import i18n from 'i18next';
import { toast } from '@components/ToastNotification/ToastManager';
import { ENDPOINTS } from '@constants/endpoints';
import { clientWithToken } from '.';
import store from '@redux/store';
import { IRichContent } from '@models/rich-content';

export const fetchRichContent = async (payload: {
  pageNumber?: number;
  pageSize?: number;
  searchText?: string;
  sortBy?: 'UPDATED_AT';
  sortOrder?: 'DESC';
  workFlowStatus?: 'LIVE'[];
  productId?: string;
  ids?: string[];
}) => {
  const url = ENDPOINTS.richContent.fetchAll;
  try {
    const response = await clientWithToken.post<{
      body: { items: IRichContent[]; pageNumber: number; totalItems: number };
    }>(url, payload);
    return response.data.body;
  } catch (error: any) {
    const { errors, body } = error;
    if (errors?.length > 0)
      toast.show({
        message: errors?.[0]?.message,
        error: true,
      });
    return {
      items: [],
      pageNumber: 0,
      totalItems: 0,
    };
  }
};

export const feedBackOnTask = async (payload: any) => {
  const feedbackContent = store.getState().richContent.feedbackContent;
  const url = `${ENDPOINTS.richContent.task}/${payload.contentId}/task-feedback/${payload.taskId}`;
  try {
    const response = await clientWithToken.post<{ body: unknown }>(
      url,
      feedbackContent,
    );
    return response?.data.body;
  } catch (error: any) {
    const { errors, body } = error;
    if (errors?.length > 0)
      toast.show({
        message: errors?.[0]?.message,
        error: true,
      });
  }
};

export const patchSelectedRichContentData = async (payload: any) => {
  try {
    const url = `${ENDPOINTS.richContent.base}/enrich/${payload.richContentId}${
      payload.updateAttributes ? '?taskType=' + payload.updateAttributes : ''
    }`;
    const response = await clientWithToken.patch<{
      body: IRichContent;
    }>(url, payload.data);
    return response?.data.body;
  } catch (error: any) {
    const { errors, body } = error;
    if (errors?.length > 0)
      toast.show({
        message: errors?.[0]?.message,
        error: true,
      });
  }
};

export const updateRichContent = async (payload: any) => {
  const url = `${ENDPOINTS.richContent.base}/enrich`;
  try {
    return await clientWithToken.put<{ body: unknown }>(url, payload);
  } catch (error: any) {
    const { errors, body } = error;
    if (errors?.length > 0)
      toast.show({
        message: errors?.[0]?.message,
        error: true,
      });
    return { errors };
  }
};

export const createRichContent = async (payload: any) => {
  const url = `${ENDPOINTS.richContent.base}/enrich`;
  try {
    return await clientWithToken.post<{ body: unknown }>(url, payload);
  } catch (error: any) {
    const { errors, body } = error;
    if (errors?.length > 0)
      toast.show({
        message: errors?.[0]?.message,
        error: true,
      });
    return { errors };
  }
};

export const createTask = async (richContentid: any, payload: any) => {
  const url = `${ENDPOINTS.richContent.task}/${richContentid}/tasks`;
  try {
    await clientWithToken.post<unknown>(url, payload);
  } catch (error: any) {
    const { errors, body } = error;
    if (errors?.length > 0)
      toast.show({
        message: errors?.[0]?.message,
        error: true,
      });
  }
};

export const updateTask = async (richContentid: any, payload: any) => {
  const url = `${ENDPOINTS.richContent.task}/${richContentid}/update-assignee`;
  try {
    await clientWithToken.put<unknown>(url, payload);
  } catch (error: any) {
    const { errors, body } = error;
    if (errors?.length > 0)
      toast.show({
        message: errors?.[0]?.message,
        error: true,
      });
  }
};

export const moveTaskForReview = async (richContentid: any, taskId: any) => {
  const url = `${ENDPOINTS.richContent.task}/${richContentid}/task-status/${taskId}`;
  try {
    await clientWithToken.patch<unknown>(url);
  } catch (error: any) {
    const { errors, body } = error;
    if (errors?.length > 0)
      toast.show({
        message: errors?.[0]?.message,
        error: true,
      });
  }
};

//! FOR ONLY WORKFLOW OFF
export const moveTaskToComplete = async (richContentid: any) => {
  const { submitTasks } = ENDPOINTS.richContent;
  const url = `${submitTasks}/${richContentid}`;
  try {
    await clientWithToken.patch<unknown>(url);
  } catch (error: any) {
    const { errors, body } = error;
    if (errors?.length > 0)
      toast.show({
        message: errors?.[0]?.message,
        error: true,
      });
  }
};

export const approveTask = async (richContentid: any, taskId: any) => {
  const url = `${ENDPOINTS.richContent.task}/${richContentid}/approve/${taskId}`;
  try {
    const response = await clientWithToken.patch<{ body: unknown }>(url);
    return {
      message: response.data.body,
      isSuccess: true,
    };
  } catch (error: any) {
    const { errors, body } = error;
    if (errors?.length > 0)
      toast.show({
        message: errors?.[0]?.message,
        error: true,
      });
  }
};

export const startReview = async (richContentid: any, taskId: any) => {
  const url = `${ENDPOINTS.richContent.task}/${richContentid}/start-review/${taskId}`;
  return clientWithToken.patch<unknown>(url);
};

export const publishRichContent = async (richContentid: string) => {
  const url = `${ENDPOINTS.richContent.publish}/${richContentid}`;
  try {
    const response = await clientWithToken.patch<{ body: unknown }>(url, {});
    return {
      message: response.data.body,
      isSuccess: true,
    };
  } catch (errorObj: any) {
    const { errors } = errorObj;
    return {
      message: errors?.[0]?.message,
      isSuccess: false,
    };
  }
};

export const deleteRichContents = async (richContentsId: string[]) => {
  const url = `${ENDPOINTS.richContent.enrich}/${richContentsId.join(',')}`;
  try {
    await clientWithToken.delete<unknown>(url, {});
    toast.show({
      message: `${i18n.t(
        `rich_content.${richContentsId.length > 1 ? 'rich_contents' : 'title'}`,
      )} ${i18n.t('global.deleted_successfully')}`,
      error: false,
    });
    return {
      message: '',
      isSuccess: true,
    };
  } catch (errorObj: any) {
    const { errors, body } = errorObj;
    toast.show({
      message: errors?.[0]?.message,
      error: true,
    });
    return {
      message: errors?.[0]?.message,
      body,
      isSuccess: false,
    };
  }
};

export const removeUsage = async (
  richContentId: string,
  prescriptiveId: string,
) => {
  const { queryParams } = ENDPOINTS.dam;
  const url = `${ENDPOINTS.richContent.removeUsage}/${richContentId}?${queryParams.consumerId}=${prescriptiveId}&${queryParams.contentConsumer}=CONTEXTUALISED_VERSION`;
  try {
    const response = await clientWithToken.patch<{ body: unknown }>(url, {});
    return {
      message: response.data.body,
      isSuccess: true,
    };
  } catch (errorObj: any) {
    const { errors } = errorObj;
    return {
      message: errors?.[0]?.message,
      isSuccess: false,
    };
  }
};
