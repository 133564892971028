import { useEffect, useRef, useState } from 'react';
import { Editor as EditorComp } from '@tinymce/tinymce-react';
import DigitalAssetsModal from '@components/DigitalAssetsModal';
import { Editor } from 'tinymce';
import { ALL_IMAGES, ALL_VIDEOS, ASSET_TYPES } from '@utils/files';
import './style.scss';

interface IRichTextEditor {
  id: string;
  value: string | undefined;
  onChange: Function;
  showCopyIntoEditor?: boolean;
  limit?: any; // TODO remove
  valid?: boolean;
}

const RichTextEditor: React.FC<IRichTextEditor> = ({
  value,
  onChange,
  showCopyIntoEditor,
  valid = true,
}) => {
  const [valueCache, setValueCache] = useState(value);
  const [showAssetModal, setShowAssetModal] = useState(false);
  const editorRef = useRef<Editor>();
  const editorContainerRef = useRef<HTMLElement | null>(null);

  useEffect(() => {
    if (showCopyIntoEditor || valueCache === undefined) setValueCache(value);
  }, [showCopyIntoEditor, value]);

  useEffect(() => {
    if (editorContainerRef.current) {
      if (valid) {
        editorContainerRef.current.classList.add('custom-editor-border');
        editorContainerRef.current.classList.remove(
          'custom-editor-border-limit',
        );
      } else {
        editorContainerRef.current.classList.remove('custom-editor-border');
        editorContainerRef.current.classList.add('custom-editor-border-limit');
      }
    }
  }, [valid]);

  return (
    <>
      <EditorComp
        tinymceScriptSrc={process.env.PUBLIC_URL + '/tinymce/tinymce.min.js'}
        initialValue={valueCache}
        onEditorChange={(str) => onChange(str)}
        init={{
          height: 200,
          menubar: false,
          paste_data_images: false,
          elementpath: false,
          plugins: [
            'advlist',
            'autolink',
            'lists',
            'link',
            'image',
            'charmap',
            'anchor',
            'searchreplace',
            'visualblocks',
            'code',
            'fullscreen',
            'insertdatetime',
            'media',
            'table',
            'preview',
            'powerpaste',
          ],
          toolbar:
            'undo redo | blocks | ' +
            'bold italic forecolor | alignleft aligncenter ' +
            'alignright alignjustify | bullist numlist custom | ' +
            'removeformat | code',
          content_style:
            'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }',
          branding: false,
          setup: (editor) => {
            editor.on('init', () => {
              editorRef.current = editor;
              editorContainerRef.current = editor.getContainer();
              if (valid) {
                editorContainerRef.current.classList.add(
                  'custom-editor-border',
                );
              } else {
                editorContainerRef.current.classList.add(
                  'custom-editor-border-limit',
                );
              }
            });
          },
        }}
        onInit={(_, editor) => {
          editorRef.current = editor;
          editor.ui.registry.addButton('custom', {
            icon: 'image',
            onAction: () => setShowAssetModal(true),
          });
        }}
      />
      <DigitalAssetsModal
        showModal={showAssetModal}
        setShowModal={setShowAssetModal}
        onClose={() => setShowAssetModal(false)}
        allowedFiles={[...ALL_VIDEOS, ...ALL_IMAGES]}
        setFinalFiles={(result) => {
          const editor = editorRef.current;
          setShowAssetModal(false);
          if (!editor) return;
          for (const file of result) {
            const type =
              ASSET_TYPES.find((a) => a.extension === file.format)?.type ||
              'image';
            const elementType = type === 'video' ? 'video' : 'img';
            const element = editor.dom.createHTML(elementType, {
              src: file.url,
              alt: file.altText,
              width: '300px',
              ...(type === 'video' && {
                autoplay: 'true',
                muted: 'true',
                loop: 'true',
              }),
            });
            editor.insertContent(element);
          }
        }}
        maxFiles={Number.MAX_SAFE_INTEGER}
      />
    </>
  );
};

export default RichTextEditor;
