import { capitalizeFirstLetter } from '@utils/textTransform';
import { useTranslation } from 'react-i18next';
import lockClosedIcon from '@assets/icons/lock_closed.svg';
import lockOpenIcon from '@assets/icons/lock_open.svg';

import './LockOverlay.scss';

const LockOverlay = ({
  locked,
  children,
  className = '',
}: {
  locked: boolean;
  children: any;
  className?: string;
}) => {
  const { t } = useTranslation();

  if (!locked) return <>{children}</>;
  return (
    <div className={`LockOverlay ${className}`}>
      <div className="LockOverlay__content">{children}</div>

      <a className="LockOverlay__overlay" href="/settings/billing">
        <img
          className="LockOverlay__locked"
          src={lockClosedIcon}
          alt={capitalizeFirstLetter(t('global.locked'))}
        />

        <img
          className="LockOverlay__unlocked"
          src={lockOpenIcon}
          alt={capitalizeFirstLetter(t('global.locked'))}
        />
      </a>
    </div>
  );
};
export default LockOverlay;
