import SkeletonText from '../SkeletonText';

const GalleryLoading = () => {
  return (
    <div className="AssetsContainer">
      <div className="AssetsContainer__details">
        <SkeletonText
          style={{
            width: '50%',
            height: 10,
          }}
        />
      </div>
      <div className="AssetsContainer__assetList">
        {[1, 2, 3, 4, 5, 6, 7].map((el) => (
          <SkeletonText
            className="ActionOverlay"
            key={el}
            style={{
              borderRadius: 10,
            }}
          />
        ))}
      </div>
      <div className="AssetsContainer__details">
        <SkeletonText
          style={{
            width: '50%',
            height: 10,
          }}
        />
      </div>
    </div>
  );
};

export default GalleryLoading;
