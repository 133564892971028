import {
  ApolloClient,
  ApolloLink,
  HttpLink,
  InMemoryCache,
} from '@apollo/client';
import store from '@redux/store';
import { BASE } from '..';

export const graphClient = new ApolloClient({
  link: ApolloLink.from([
    new ApolloLink((operation, forward) => {
      const kcInstance = store.getState().keyCloak.keyCloak;

      operation.setContext(({ headers = {}, uri = '' }) => ({
        headers: {
          ...headers,
          'X-TenantID': kcInstance.clientId,
          authorization: `Bearer ${kcInstance.token}`,
        },
        uri: BASE + '/analytics/graphql?operation=' + operation.operationName,
      }));

      return forward(operation);
    }),
    new HttpLink({ uri: BASE + '/analytics/graphql' }),
  ]),
  cache: new InMemoryCache(),
});
