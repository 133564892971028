import {
  ActionCreatorWithPayload,
  Dispatch,
  Middleware,
  UnknownAction,
} from '@reduxjs/toolkit';
import { DefaultRootState } from 'react-redux';

/** This is so we can keep track of who already reacted to the event to prevent infinite loops */
const SIDE_EFFECT_STACK = Symbol('SIDE_EFFECT_STACK');

type Executor<ActionType extends ActionCreatorWithPayload<any, string>[]> =
  (params: {
    state: DefaultRootState;
    dispatch: Dispatch<UnknownAction>;
    action: ActionType[number];
  }) => Promise<void> | void;

const allSideEffects: { [actionType: string]: Executor<any>[] | undefined } =
  {};

type SideEffect<
  T extends ActionCreatorWithPayload<any, string>[] = ActionCreatorWithPayload<
    any,
    string
  >[],
> = {
  /** This is for documentation purposes only and doesn't affect functionality */
  name: string;
  dependsOn: T;
  execute: Executor<T>;
};

export const registerSideEffects = (...sideEffects: SideEffect[]) => {
  for (const sideEffect of sideEffects) {
    for (const creator of sideEffect.dependsOn) {
      const type = creator(null).type;
      const sideEffectList =
        allSideEffects[type] || (allSideEffects[type] = []);
      sideEffectList.push(sideEffect.execute);
    }
  }
};

// TODO if a sideEffect depends on two actions run together it also runs twice
export const SideEffectsMiddleware: Middleware = (store) => {
  return (next) => (action) => {
    // Call the next middleware in the chain (or the reducer if it's the last one)
    const result = next(action);
    if (
      !(
        action &&
        typeof action === 'object' &&
        'type' in action &&
        typeof action.type === 'string' &&
        'payload' in action &&
        (!(SIDE_EFFECT_STACK in action) || action[SIDE_EFFECT_STACK] !== true)
      )
    )
      return;
    const { type, payload } = action;
    const sideEffectStack = (
      SIDE_EFFECT_STACK in action ? action[SIDE_EFFECT_STACK] : new Set()
    ) as Set<Function>;
    const executors = allSideEffects[type];
    if (!executors) return;

    // Add new executors as a to the list of sideEffects that were already triggered to prevent infinite loops
    const newSideEffectStack = new Set(sideEffectStack);
    executors.forEach(newSideEffectStack.add.bind(newSideEffectStack));

    // Access the updated state after the action is processed
    const state = store.getState() as DefaultRootState;
    for (const executor of executors) {
      // Don't process this sideEffect if the action has already stemmed from it
      if (sideEffectStack.has(executor)) continue;

      const dispatch: Dispatch<UnknownAction> = (arg) => {
        // For any action this side effect emits, add the side effect stack
        Object.assign(arg, { [SIDE_EFFECT_STACK]: newSideEffectStack });
        return store.dispatch(arg);
      };
      executor({ state, dispatch, action });
    }

    // Return the result of the original action
    return result;
  };
};
