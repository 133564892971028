import { gql, TypedDocumentNode } from '@apollo/client';

/** Data broken down by base, contextualized, and test traffic */
export type RateData = {
  /** The performance of the control/base product/page */
  base: number;
  /** The combined performance of all versions for the product/page */
  version: number;
  /** The combined performance of all test versions for the product/page */
  test: number;
  /** The combined performance of all contextualized versions for the product/page */
  contextualized: number;
};

/** Data compared from base to heuristic versions */
export type TimeSeriesData =
  | {
      /** The date of the entry */
      date: string;
      /** The performance of the control/base product/page for that date */
      base: number;
      /** The combined performance of all versions for the product/page for that date */
      version: number;
      /** The combined performance of all test versions for the product/page for that date */
      test: number;
      /** The combined performance of all contextualized versions for the product/page for that date */
      contextualized: number;
    }[]
  | null;

export type FilterTab = {
  label: string;
  id: string;
  query: TypedDocumentNode<{
    product: { [key: string]: { timeseries: TimeSeriesData } };
  }>;
};
/** // TODO unify with {@see FilterTab }  */
export type FilterTabTitle = {
  title: string;
  id: string;
  query: TypedDocumentNode<{
    product: { [key: string]: { timeseries: TimeSeriesData } };
  }>;
};
/** // TODO unify with {@see FilterTab }  */
export type FilterTabPage = {
  label: string;
  id: string;
  query: TypedDocumentNode<{
    page: { [key: string]: { timeseries: TimeSeriesData } };
  }>;
};

export const GET_CURRENCY_ONBOARD_DATE: TypedDocumentNode<{
  metadata: {
    currency: string;
    onboardedOn: string;
    storeUrl: string;
    defaultRange: string;
  };
}> = gql`
  query currency {
    metadata {
      currency
      onboardedOn
      storeUrl
      defaultRange
    }
  }
`;

export const GET_DASHBOARD_REFRESH_TIME: TypedDocumentNode<{
  metadata?: { batch?: { cooldown_period?: number; refreshed_at?: number } };
}> = gql`
  query dashboard_update_time {
    metadata {
      batch {
        cooldown_period
        refreshed_at
      }
    }
  }
`;

export const GET_ADDED_TO_REVENUE: TypedDocumentNode<{
  product: { conversion: { aggregate: RateData; rate: RateData } };
}> = gql`
  query added_to_revenue($start: String!, $end: String!) {
    product(start: $start, end: $end, filters: { ssd: true }) {
      conversion {
        aggregate(start: $start, end: $end, filters: { ssd: true }) {
          base
          version
          test
          contextualized
        }
        rate(start: $start, end: $end, filters: { ssd: true }) {
          base
          version
          test
          contextualized
        }
      }
    }
  }
`;

export const GET_ADDED_TO_CART: TypedDocumentNode<{
  product: { cart: { aggregate: RateData; rate: RateData } };
}> = gql`
  query added_to_cart($start: String!, $end: String!) {
    product(start: $start, end: $end, filters: { ssd: true }) {
      cart {
        aggregate {
          base
          version
          test
          contextualized
        }
        rate {
          base
          version
          test
          contextualized
        }
      }
    }
  }
`;

export const GET_VERSION_SESSIONS: TypedDocumentNode<{
  product: { sessions: { aggregate: RateData } };
}> = gql`
  query version_sessions($start: String!, $end: String!) {
    product(start: $start, end: $end, filters: { ssd: true }) {
      sessions {
        aggregate {
          base
          version
          test
          contextualized
        }
      }
    }
  }
`;

export const GET_PRODUCTS_OPTIMIZED: TypedDocumentNode<{
  metadata: { products: { total: number; optimized: number } };
}> = gql`
  query products_optimized {
    metadata {
      products {
        total
        optimized
      }
    }
  }
`;

export const GET_PRODUCT_VERSION_DATA: TypedDocumentNode<{
  metadata: { versions: { total: number; learning: number } };
}> = gql`
  query products_optimized($start: String!, $end: String!) {
    metadata(start: $start, end: $end) {
      versions {
        total
        learning
      }
    }
  }
`;

export const GET_REACHED_CHECKOUT_DATA: TypedDocumentNode<{
  product: { checkout: { rate: RateData } };
}> = gql`
  query reached_checkout($start: String!, $end: String!) {
    product(start: $start, end: $end) {
      checkout {
        rate {
          base
          version
          test
          contextualized
        }
      }
    }
  }
`;

export const GET_AVG_TIME_SPENT_ON_PAGE: TypedDocumentNode<{
  product: { time_spent: { rate: RateData } };
}> = gql`
  query avg_time_on_page($start: String!, $end: String!) {
    product(start: $start, end: $end) {
      time_spent {
        rate {
          base
          version
          test
          contextualized
        }
      }
    }
  }
`;

export const GET_CONVERSION_RATE: TypedDocumentNode<{
  product: { conversion: { rate: RateData } };
}> = gql`
  query conversion_rate(
    $start: String!
    $end: String!
    $product_id: String
    $version_id: String
    $context_id: String
  ) {
    product(
      start: $start
      end: $end
      filters: {
        product_id: $product_id
        version_id: $version_id
        context_id: $context_id
        ssd: true
      }
    ) {
      conversion {
        rate {
          base
          version
          test
          contextualized
        }
      }
    }
  }
`;

export const GET_PRODUCT_PERFORMANCE_CHECKOUT: TypedDocumentNode<{
  product: { checkout: { timeseries: TimeSeriesData } };
}> = gql`
  query product_checkout_timeseries($start: String!, $end: String!) {
    product(start: $start, end: $end) {
      checkout {
        timeseries {
          base
          version
          test
          contextualized
          date
        }
      }
    }
  }
`;

export const GET_PRODUCT_PERFORMANCE_EXIT_RATE: TypedDocumentNode<{
  product: { exit: { timeseries: TimeSeriesData } };
}> = gql`
  query product_exit_rate_timeseries($start: String!, $end: String!) {
    product(start: $start, end: $end) {
      exit {
        timeseries {
          date
          base
          version
          test
          contextualized
        }
      }
    }
  }
`;

export const GET_ADD_TO_CART_PERCENTAGE: TypedDocumentNode<{
  product: { cart: { rate: RateData } };
}> = gql`
  query atc_percentage(
    $start: String!
    $end: String!
    $product_id: String
    $version_id: String
    $context_id: String
  ) {
    product(
      start: $start
      end: $end
      filters: {
        product_id: $product_id
        version_id: $version_id
        context_id: $context_id
        ssd: true
      }
    ) {
      cart {
        rate {
          base
          version
          test
          contextualized
        }
      }
    }
  }
`;

export const GET_BOUNCE_RATE_PERCENTAGE: TypedDocumentNode<{
  product: { bounce: { rate: RateData } };
}> = gql`
  query bounce_rate_percentage(
    $start: String!
    $end: String!
    $product_id: String
    $version_id: String
    $context_id: String
  ) {
    product(
      start: $start
      end: $end
      filters: {
        product_id: $product_id
        version_id: $version_id
        context_id: $context_id
        ssd: true
      }
    ) {
      bounce {
        rate {
          base
          version
          test
          contextualized
        }
      }
    }
  }
`;

export const GET_PRODUCT_PERFORMANCE_ATC: TypedDocumentNode<{
  product: { cart: { timeseries: TimeSeriesData } };
}> = gql`
  query product_performance_atc(
    $start: String!
    $end: String!
    $product_id: String
    $version_id: String
  ) {
    product(
      start: $start
      end: $end
      filters: { product_id: $product_id, version_id: $version_id, ssd: true }
    ) {
      cart {
        timeseries {
          date
          base
          version
          test
          contextualized
        }
      }
    }
  }
`;

export const GET_PRODUCT_PERFORMANCE_BOUNCE: TypedDocumentNode<{
  product: { bounce: { timeseries: TimeSeriesData } };
}> = gql`
  query product_performance_bounce(
    $start: String!
    $end: String!
    $product_id: String
    $version_id: String
    $context_id: String
  ) {
    product(
      start: $start
      end: $end
      filters: {
        product_id: $product_id
        version_id: $version_id
        context_id: $context_id
        ssd: true
      }
    ) {
      bounce {
        timeseries {
          date
          base
          version
          test
          contextualized
        }
      }
    }
  }
`;
export const GET_PRODUCT_PERFORMANCE_CONVERSION_RATE: TypedDocumentNode<{
  product: { conversion: { timeseries: TimeSeriesData } };
}> = gql`
  query product_performance_conversion_rate(
    $start: String!
    $end: String!
    $product_id: String
    $version_id: String
    $context_id: String
  ) {
    product(
      start: $start
      end: $end
      filters: {
        product_id: $product_id
        version_id: $version_id
        context_id: $context_id
        ssd: true
      }
    ) {
      conversion {
        timeseries {
          date
          base
          version
          test
          contextualized
        }
      }
    }
  }
`;

export const GET_PERFORMANCE_DELIVERY_SPEED: TypedDocumentNode<{
  system: { delivery_speed: { range: string; deliveries: number }[] };
}> = gql`
  query product_performance_delivery_speed($start: String!, $end: String!) {
    system(start: $start, end: $end) {
      delivery_speed {
        range
        deliveries
      }
    }
  }
`;

export const GET_TOTAL_SESSIONS: TypedDocumentNode<
  { product: { sessions: { aggregate: RateData } } },
  { start: string; end: string; ssd: boolean }
> = gql`
  query total_delivery($start: String!, $end: String!, $ssd: Boolean!) {
    product(start: $start, end: $end, filters: { ssd: $ssd }) {
      sessions {
        aggregate {
          base
          version
          test
          contextualized
        }
      }
    }
  }
`;

export const GET_ANALYTICS_PERIOD_DASHBOARD: TypedDocumentNode<{
  insight: { data_period: { start: string; end: string } };
}> = gql`
  query analytics_period_dashboard {
    insight {
      data_period {
        start
        end
      }
    }
  }
`;

export const GET_CONTEXT_DELIVERY: TypedDocumentNode<{
  context: { aggregate: { name: string; visits: number }[] | null };
}> = gql`
  query context_delivery($start: String!, $end: String!) {
    context(start: $start, end: $end) {
      aggregate {
        name
        visits
      }
    }
  }
`;

export const GET_SPOTLIGHT: TypedDocumentNode<{
  metadata: { spotlight: { video: string } };
}> = gql`
  query spotlight {
    metadata {
      spotlight {
        video
      }
    }
  }
`;

export const GET_DASHBOARD_VIEWABLE_SECTIONS: TypedDocumentNode<{
  metadata: {
    dashboard: {
      product: {
        product: boolean;
        sessions: boolean;
      };
      conversion: {
        cart: boolean;
        conversion: boolean;
      };
      rate: {
        cart: boolean;
        bounceRate: boolean;
        conversion: boolean;
      };
      performance: {
        cart: boolean;
        bounceRate: boolean;
        experienceScore: boolean;
        conversion: boolean;
      };
      system: {
        deliverySpeed: boolean;
        uptime: boolean;
      };
      delivery: {
        context: boolean;
        contextualization: boolean;
      };
      blog: {
        blogs: boolean;
        spotlight: boolean;
      };
    };
  };
}> = gql`
  query dashboard_sections {
    metadata {
      dashboard {
        product {
          product
          sessions
        }
        conversion {
          cart
          conversion
        }
        rate {
          cart
          bounceRate
          conversion
        }
        performance {
          cart
          bounceRate
          experienceScore
          conversion
        }
        system {
          deliverySpeed
          uptime
        }
        delivery {
          context
          contextualization
        }
        blog {
          blogs
          spotlight
        }
      }
    }
  }
`;

export const GET_PERFORMANCE_CHECKOUT_RATE: TypedDocumentNode<{
  page: { reached_checkout: { timeseries: TimeSeriesData } };
}> = gql`
  query getCheckoutTimeseries(
    $start: String!
    $end: String!
    $page_id: String
    $version_id: String
  ) {
    page(
      start: $start
      end: $end
      filters: { page_id: $page_id, version_id: $version_id }
    ) {
      reached_checkout {
        timeseries {
          date
          base
          version
          test
          contextualized
        }
      }
    }
  }
`;

export const GET_PERFORMANCE_CONVERSION_RATE: TypedDocumentNode<{
  page: { conversion: { timeseries: TimeSeriesData } };
}> = gql`
  query getConversionTimeseries(
    $start: String!
    $end: String!
    $page_id: String
    $version_id: String
  ) {
    page(
      start: $start
      end: $end
      filters: { page_id: $page_id, version_id: $version_id }
    ) {
      conversion {
        timeseries {
          date
          base
          version
          test
          contextualized
        }
      }
    }
  }
`;

export const GET_PERFORMANCE_ATC_PERCENTAGE: TypedDocumentNode<{
  page: { cart: { timeseries: TimeSeriesData } };
}> = gql`
  query getCartTimeseries(
    $start: String!
    $end: String!
    $page_id: String
    $version_id: String
  ) {
    page(
      start: $start
      end: $end
      filters: { page_id: $page_id, version_id: $version_id }
    ) {
      cart {
        timeseries {
          date
          base
          version
          test
          contextualized
        }
      }
    }
  }
`;

export const GET_PERFORMANCE_AVG_TIME_SPENT: TypedDocumentNode<{
  page: { avg_time_spent: { timeseries: TimeSeriesData } };
}> = gql`
  query getAvgTimeSpentTimeseries(
    $start: String!
    $end: String!
    $page_id: String
    $version_id: String
  ) {
    page(
      start: $start
      end: $end
      filters: { page_id: $page_id, version_id: $version_id }
    ) {
      avg_time_spent {
        timeseries {
          date
          base
          version
          test
          contextualized
        }
      }
    }
  }
`;

export const GET_PERFORMANCE_BOUNCE_RATE: TypedDocumentNode<{
  page: { bounce: { timeseries: TimeSeriesData } };
}> = gql`
  query getBounceTimeseries(
    $start: String!
    $end: String!
    $page_id: String
    $version_id: String
  ) {
    page(
      start: $start
      end: $end
      filters: { page_id: $page_id, version_id: $version_id }
    ) {
      bounce {
        timeseries {
          date
          base
          version
          test
          contextualized
        }
      }
    }
  }
`;

export const GET_PERFORMANCE_EXIT_RATE: TypedDocumentNode<{
  page: { exit: { timeseries: TimeSeriesData } };
}> = gql`
  query getExitTimeseries(
    $start: String!
    $end: String!
    $page_id: String
    $version_id: String
  ) {
    page(
      start: $start
      end: $end
      filters: { page_id: $page_id, version_id: $version_id }
    ) {
      exit {
        timeseries {
          date
          base
          version
          test
          contextualized
        }
      }
    }
  }
`;

export const GET_PERFORMANCE_SESSION_LENGTH: TypedDocumentNode<{
  page: { avg_session_duration: { timeseries: TimeSeriesData } };
}> = gql`
  query getAvgSessionDurationTimeseries(
    $start: String!
    $end: String!
    $page_id: String
    $version_id: String
  ) {
    page(
      start: $start
      end: $end
      filters: { page_id: $page_id, version_id: $version_id }
    ) {
      avg_session_duration {
        timeseries {
          date
          base
          version
          test
          contextualized
        }
      }
    }
  }
`;

export const GET_PERFORMANCE_PAGE_VIEWS: TypedDocumentNode<{
  page: { avg_session_traffic: { timeseries: TimeSeriesData } };
}> = gql`
  query getAvgSessionTrafficTimeseries(
    $start: String!
    $end: String!
    $page_id: String
    $version_id: String
  ) {
    page(
      start: $start
      end: $end
      filters: { page_id: $page_id, version_id: $version_id }
    ) {
      avg_session_traffic {
        timeseries {
          date
          base
          version
          test
          contextualized
        }
      }
    }
  }
`;

export const GET_CONTEXT_PERFORMANCE_CONVERSION_RATE: TypedDocumentNode<{
  page: { conversion: { timeseries: TimeSeriesData } };
}> = gql`
  query getContextConversionTimeseries(
    $start: String!
    $end: String!
    $page_id: String
    $version_id: String
    $context_id: String
  ) {
    page(
      start: $start
      end: $end
      filters: {
        page_id: $page_id
        version_id: $version_id
        context_id: $context_id
      }
    ) {
      conversion {
        timeseries {
          date
          base
          version
          test
          contextualized
        }
      }
    }
  }
`;

export const GET_CONTEXT_PERFORMANCE_BOUNCE_RATE: TypedDocumentNode<{
  page: { bounce: { timeseries: TimeSeriesData } };
}> = gql`
  query getContextBounceTimeseries(
    $start: String!
    $end: String!
    $page_id: String
    $version_id: String
    $context_id: String
  ) {
    page(
      start: $start
      end: $end
      filters: {
        page_id: $page_id
        version_id: $version_id
        context_id: $context_id
      }
    ) {
      bounce {
        timeseries {
          date
          base
          version
          test
          contextualized
        }
      }
    }
  }
`;

export const GET_CONTEXT_PERFORMANCE_ATC_PERCENTAGE: TypedDocumentNode<{
  page: { cart: { timeseries: TimeSeriesData } };
}> = gql`
  query getContextCartTimeseries(
    $start: String!
    $end: String!
    $page_id: String
    $version_id: String
    $context_id: String
  ) {
    page(
      start: $start
      end: $end
      filters: {
        page_id: $page_id
        version_id: $version_id
        context_id: $context_id
      }
    ) {
      cart {
        timeseries {
          date
          base
          version
          test
          contextualized
        }
      }
    }
  }
`;
