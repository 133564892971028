/**
 * @author Rishabh
 * @description AssetGalleryComponent : ON UPLOAD, DELETE & DROP IT WILL ALWAYS RETURNS UPDATED ARRAY OF IMAGES
 * @param setImages = () => {}
 * @param maxLimit = images.length,
 * @returns JSX.Element
 */

import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { arrayMoveImmutable } from 'array-move';

import AssetPreviewModal from '../AssetPreviewModal';
import DigitalAssetsModal from '../DigitalAssetsModal';
import ConfirmationModal, { ActionButtonProps } from '../ConfirmationModal';
import ActionOverlay from '../ActionOverLay';
import { DragAndDropContainer, DragAndDropElement } from '../DNDSortable';
import GalleryLoading from './Loading';
import useLegacyModal from '../ModalLayout/useModal';
import {
  deleteAsset,
  getFinalImagesAfterUpload,
  getUrlExtension,
  mapToOriginalPosition,
} from '@utils/assetUtils';
import {
  capitalizeEveryFirstLetter,
  capitalizeFirstLetter,
} from '@utils/textTransform';
import { ALL_IMAGES, ALL_VIDEOS } from '@utils/files';
import { DigitalAssetBrief } from '@models/digital-asset';
import { getThumbnail } from '@utils/getThumbnail';
import VideoOverlay from '../VideoOverlay';

import { ReactComponent as AssetGalleryAddIcon } from '@assets/icons/Group 7619.svg';
import { ReactComponent as DeleteIcon } from '@assets/icons/TrashIcon.svg';
import { ReactComponent as FullScreenIcon } from '@assets/icons/FullScreenIcon.svg';
import { ReactComponent as DragIcon } from '@assets/icons/DragIcon.svg';
import { ReactComponent as EditIcon } from '@assets/icons/EditIcon.svg';
import { ReactComponent as PdfIcon } from '@assets/icons/pdf.svg';

import './AssetGallery.scss';
import LockOverlay from '../LockOverlay';

export interface Image {
  id: string;
  url: any;
  position: number;
  assetId: string;
  altText: string;
  type: 'image' | 'video' | 'doc';
  assetTitle: string;
}

interface AssetGalleryProps {
  // List of Images
  images: Image[];
  // Lift State of Images for Parent Component
  setImages?: any;
  // Gallery Tilte
  galleryTitle: string;
  // Gallery Description
  galleryDescription: string;
  // Image Gallery is editable or not
  editable: boolean;
  // Maximum images to be shown in Image Gallery
  maxLimit?: number;
  minLimit?: number;
  error?: boolean;
  loading?: boolean;
  fullyEnabled: boolean;
}

const AssetGallery: React.FC<AssetGalleryProps> = ({
  images,
  setImages = () => {
    return;
  },
  galleryTitle,
  galleryDescription,
  editable = false,
  error = false,
  loading = true,
  maxLimit = images.length,
  fullyEnabled,
  minLimit,
}) => {
  // i18n hook
  const { t } = useTranslation();
  // Crop Modal ID
  // const cropModalId = 'crop-modal';
  const damModalId = 'dam-modal';
  const deleteModalId = 'delete-modal';
  const fullscreenModalId = 'fullscreenModalId';
  // Hooks for Image Modal
  const { openModal, closeModal, showModalId } = useLegacyModal();
  const [selectedImage, setSelectedImage] = useState<Image | null>();

  const [previewAssets, setPreviewAssets] = useState<any>([]);
  // Using any here beacause current DigitalAssetBrief interface
  // has 10 more params which are not availble here and are mandatory

  const setAssetsForPreview = () => {
    const assets = images?.map((image) => ({
      id: image?.assetId,
      title: image?.assetTitle,
      format: getUrlExtension(image?.url),
      url: image?.url,
    }));
    setPreviewAssets([...assets]);
  };

  const moveAssetToFirstPosition = () => {
    const assets = previewAssets.filter(
      (asset: any) => asset.id !== selectedImage?.assetId,
    );
    assets.unshift({
      id: selectedImage?.assetId,
      title: selectedImage?.assetTitle,
      format: getUrlExtension(selectedImage?.url),
      url: selectedImage?.url,
    });
    setPreviewAssets([...assets]);
  };

  useEffect(() => {
    if (images.length > 0) setAssetsForPreview();
  }, [images]);

  useEffect(() => {
    if (selectedImage) moveAssetToFirstPosition();
  }, [selectedImage]);

  // Process all Images selected from DAM model
  const onImageSletectionFromDamModal = (
    damModalImages: DeepReadonly<DigitalAssetBrief[]>,
  ) => {
    if (damModalImages.length > 0) {
      const response = getFinalImagesAfterUpload(
        images,
        damModalImages,
        selectedImage,
      );
      setImages(response);
    }
  };

  // Delete Image Up Handler
  const handleDelete = () => {
    setImages(deleteAsset(selectedImage?.position, images));
    setSelectedImage(null);
    closeModal();
  };

  const handleCloseModal = () => {
    setSelectedImage(null);
    closeModal();
  };

  const handleOpenModal = (assetObj: Image, modalId: string) => {
    setSelectedImage(assetObj);
    openModal(modalId);
  };

  const onSortEnd = ({ oldIndex, newIndex }: any) => {
    setImages(
      mapToOriginalPosition(arrayMoveImmutable(images, oldIndex, newIndex)),
    );
  };

  // Delete modal layout
  const deleteModal = {
    modalId: deleteModalId,
    title: capitalizeEveryFirstLetter(
      `${t('global.remove')} ${t('global.image')}`,
    ),
    confirmationStatement: capitalizeFirstLetter(
      `${t('taskMaster.removeConfirmationSingle')}`,
    ),
    clickHandler: handleDelete,
    actionButtons: [
      {
        text: capitalizeFirstLetter(`${t('global.no_cancel')}`),
        color: 'gray',
        type: 'cancel',
      },
      {
        text: capitalizeFirstLetter(`${t('global.yes_remove')}`),
        color: 'red',
        type: 'submit',
      },
    ] as ActionButtonProps[],
  };

  // Full screen modal layout
  const fullscreenModal = {
    modalId: fullscreenModalId,
    openModal: () => {
      openModal(fullscreenModalId);
    },
  };

  // Image Gallery Header Component
  const AssetGalleryHeader = () => {
    return (
      <>
        <h6 className="AssetsContainer__details--description">
          {capitalizeFirstLetter(t('taskMaster.primary_assets_for'))}
          {galleryTitle}
          {minLimit && editable && (
            <span
              className={`AssetsContainer__details--description ${
                error ? 'maxLimit__danger' : ''
              }`}
            >
              {' '}
              <i>{`(${t('taskMaster.min_asset_message', {
                value: minLimit,
              })})`}</i>
            </span>
          )}
        </h6>

        {editable && (
          <h6
            className={`AssetsContainer__details--description ${
              maxLimit === images?.length ? 'maxLimit__danger' : 'maxLimit'
            }`}
          >
            {capitalizeEveryFirstLetter(t('taskMaster.max_assets'))}:{' '}
            {images?.length + `/` + maxLimit}
          </h6>
        )}
      </>
    );
  };
  const ImageList = () => (
    <>
      <DragAndDropContainer
        axis="xy"
        onSortEnd={onSortEnd}
        disableAutoscroll={true}
        distance={5}
      >
        <div className="AssetsContainer__assetList">
          {images?.map((assetObj: Image, index: number) => (
            <DragAndDropElement key={index} index={index} disabled={!editable}>
              <ActionOverlay
                key={index}
                actions={[
                  {
                    id: 'action-delete',
                    icon: <DeleteIcon />,
                    tipText: capitalizeFirstLetter(t('global.remove')),
                    position: 'top',
                    handler: () => handleOpenModal(assetObj, deleteModalId),
                  },
                  {
                    id: 'action-fullScreen',
                    icon: <FullScreenIcon />,
                    tipText: capitalizeFirstLetter(t('taskMaster.preview')),
                    position: 'bottom',
                    handler: () => handleOpenModal(assetObj, fullscreenModalId),
                  },
                  {
                    id: 'action-edit',
                    icon: <EditIcon />,
                    tipText: capitalizeFirstLetter(
                      t('taskMaster.change_asset'),
                    ),
                    position: 'top',
                    handler: () => handleOpenModal(assetObj, damModalId),
                  },
                  {
                    id: 'action-drag',
                    icon: <DragIcon />,
                    tipText: capitalizeFirstLetter(t('global.drag')),
                    position: 'center',
                    handler: () => null,
                  },
                ]}
                disabled={!editable || (!fullyEnabled && index > 0)}
              >
                <LockOverlay locked={!fullyEnabled && index > 0}>
                  {/* //? TYPE IMAGE */}
                  {assetObj.type === 'image' && (
                    <img
                      id={assetObj.position.toString()}
                      src={getThumbnail(assetObj.url, 768)}
                      alt="image"
                      className="AssetsContainer__assetList--asset"
                    />
                  )}
                  {/* //? TYPE VIDEO */}
                  {assetObj.type === 'video' && (
                    <VideoOverlay>
                      <video
                        id={assetObj.position.toString()}
                        src={assetObj.url}
                        className="AssetsContainer__assetList--asset"
                      />
                    </VideoOverlay>
                  )}
                  {/* //? TYPE PDF */}
                  {assetObj.type === 'doc' && (
                    <PdfIcon className="AssetsContainer__assetList--asset" />
                  )}
                </LockOverlay>
              </ActionOverlay>
            </DragAndDropElement>
          ))}

          {images?.length < maxLimit && editable && <AddAsset />}
        </div>
      </DragAndDropContainer>
    </>
  );

  // Add Image Component
  const AddAsset = () => {
    return (
      <>
        {images?.length < maxLimit && editable && (
          <div
            className={`AssetsContainer__assetList--addIcon ${
              error ? 'warning' : ''
            }`}
          >
            <AssetGalleryAddIcon
              role="button"
              onClick={() => openModal(damModalId)}
            />
          </div>
        )}
      </>
    );
  };

  return (
    <>
      {!loading ? (
        <div className="AssetsContainer">
          <div className="AssetsContainer__details">
            <AssetGalleryHeader />
          </div>
          <ImageList />
          <div className="AssetsContainer__details">
            {editable && (
              <h6 className="AssetsContainer__details--description">
                {galleryDescription}
              </h6>
            )}
          </div>
        </div>
      ) : (
        <GalleryLoading />
      )}

      {/* DAM MODAL */}
      {damModalId === showModalId && (
        <DigitalAssetsModal
          showModal={damModalId === showModalId}
          setShowModal={handleCloseModal}
          onClose={handleCloseModal}
          allowedFiles={[...ALL_VIDEOS, ...ALL_IMAGES]}
          setFinalFiles={onImageSletectionFromDamModal}
          maxFiles={maxLimit === images?.length ? 1 : maxLimit - images?.length}
        />
      )}

      {/* DELETE CONFIRMATION MODAL */}
      {deleteModal?.modalId === showModalId && (
        <ConfirmationModal
          showModal={deleteModal?.modalId === showModalId}
          setShowModal={handleCloseModal}
          title={deleteModal?.title}
          statement={deleteModal?.confirmationStatement}
          buttons={deleteModal?.actionButtons}
          clickHandler={deleteModal.clickHandler}
          isDanger
        />
      )}

      {/* Preview Modal */}
      {fullscreenModal.modalId === showModalId && (
        <AssetPreviewModal
          showAssetIndex
          assets={previewAssets}
          showModal={fullscreenModal.modalId === showModalId}
          setShowModal={handleCloseModal}
          onClose={handleCloseModal}
          callToAction={{
            text: capitalizeEveryFirstLetter(t('taskMaster.change_asset')),
            callback: () => openModal(damModalId),
          }}
        />
      )}
    </>
  );
};

export default AssetGallery;
