import { combineReducers } from 'redux';
import AssetSlice from './asset';
import ContextSlice from './context';
import CurrentUserSlice from './currentUser';
import KeyCloakSlice from './keyCloak';
import MetadataSlice from './metadata';
import ModalSlice from './modal';
import PagesSlice from './pages';
import PageVersionsSlice from './pageVersions';
import ProductSlice from './product';
import ProductVersionSlice from './productVersion';
import QuickStartGuideSlice from './quickStartGuide';
import RichContentSlice from './richContent';
import SidebarSlice from './sidebar';
import SpinnerSlice from './spinner';
import TemplateSlice from './template';
import TenantSlice from './tenant';
import UtmSlice from './utm';
import VersionSlice from './version';
import WorkflowSlice from './workflow';

export const rootReducer = combineReducers({
  assets: AssetSlice,
  contexts: ContextSlice,
  currentUser: CurrentUserSlice,
  keyCloak: KeyCloakSlice,
  metadata: MetadataSlice,
  modals: ModalSlice,
  pages: PagesSlice,
  pageVersions: PageVersionsSlice,
  productVersion: ProductVersionSlice,
  products: ProductSlice,
  quickStartGuide: QuickStartGuideSlice,
  richContent: RichContentSlice,
  sidebar: SidebarSlice,
  spinner: SpinnerSlice,
  templates: TemplateSlice,
  tenant: TenantSlice,
  utms: UtmSlice,
  version: VersionSlice,
  workflow: WorkflowSlice,
});
