import React, { useEffect, useState } from 'react';

import SectionFactory from './SectionFactory';
import AddSectionModal from '../../+page/RichContentAddSelectionModal';
import useLegacyModal from '@components/ModalLayout/useModal';
import AddButtonWithSeparator from '@components/AddButtonWithSeparator';

import { IPermission, IRichContent, ITemplate } from '@models/rich-content';
import { Mixpanel } from '@utils/mixpanel';
import {
  carouselSection,
  imageTextSection,
  reviewSection,
  testimonialsSection,
  textImageSection,
  videoSection,
  videoWithTitleSection,
  videoWithTitleAndDescSection,
  imageSection,
  highlightSection,
  imageGridSection,
} from '../../+page/defaultData';

const TemplateMainContent = ({
  permissions,
  templateData,
  setTemplateData,
}: {
  permissions: IPermission;
  templateData: ITemplate[];
  setTemplateData: React.Dispatch<React.SetStateAction<ITemplate[]>>;
}) => {
  const templateSelection = 'rich-content-section-selection-modal';
  const { openModal, closeModal, showModalId } = useLegacyModal();
  const [addSectionIndex, setAddSectionIndex] = useState(0);

  const getSection = (value: string) => {
    let section;
    switch (value) {
      case 'imageText':
        section = imageTextSection;
        break;
      case 'textImage':
        section = textImageSection;
        break;
      case 'testimonial':
        section = testimonialsSection;
        break;
      case 'video':
        section = videoSection;
        break;
      case 'videoWithTitle':
        section = videoWithTitleSection;
        break;
      case 'videoWithTitleAndDesc':
        section = videoWithTitleAndDescSection;
        break;
      case 'threeReviews':
        section = reviewSection;
        break;
      case 'imageSection':
        section = imageSection;
        break;
      case 'highlights':
        section = highlightSection;
        break;
      case 'imageGrid':
        section = imageGridSection;
        break;
      default:
        section = carouselSection;
    }
    return section;
  };

  const addNewSection = (value: any) => {
    const defaultContent = getSection(value);
    const deepClonedDefaultContent = JSON.parse(JSON.stringify(defaultContent)); //Deep clone for all nested properties
    const data = JSON.parse(JSON.stringify(templateData));
    data.splice(addSectionIndex, 0, {
      ...deepClonedDefaultContent,
      order: addSectionIndex,
    });
    for (let i = addSectionIndex; i < data?.length; i++) {
      data[i].order += 1;
    }
    setTemplateData(data);
    closeModal();
  };

  const openAddNewElement = (index: number) => {
    if (!permissions.AUTHOR_CONTENT && !permissions.VISUAL_ASSETS) return;
    setAddSectionIndex(index);
    openModal(templateSelection);
  };

  const moveSection = (position: 'up' | 'down', index: number) => {
    let data;
    if (position == 'up') {
      data = templateData[index];
      templateData[index] = {
        ...templateData[index - 1],
        order: data.order,
      };
      templateData[index - 1] = {
        ...data,
        order: templateData[index - 1].order,
      };
    } else if (position == 'down') {
      data = templateData[index];
      templateData[index] = { ...templateData[index + 1], order: data.order };
      templateData[index + 1] = {
        ...data,
        order: templateData[index + 1].order,
      };
    } else {
      const finalTemplates = templateData.filter((_, key) => key !== index);
      finalTemplates.forEach((_, index) => {
        finalTemplates[index].order = index + 1;
      });
      setTemplateData([...finalTemplates]);
      return;
    }
    setTemplateData([...templateData]);
  };

  const handleChange = (
    value: string,
    field: string,
    index: string,
    isAsset = false,
    assetIds = [],
  ) => {
    const data = JSON.parse(JSON.stringify(templateData));
    if (isAsset) {
      data[index].attributes[field].assetIds = assetIds;
    }
    data[index].attributes[field].standardText = value;
    setTemplateData([...data]);

    Mixpanel.track('rich_content_operation', {
      eventName: 'Update rich content',
      kpi: 'Takes advantage of feature',
      propertyType: 'event_property',
    });
  };

  const sectionsList = templateData.map(
    (template: ITemplate, index: number) => {
      return (
        <React.Fragment key={index}>
          <SectionFactory
            key={template.id}
            permissions={permissions}
            sectionOrder={index}
            section={template}
            totalSections={templateData.length}
            moveSection={moveSection}
            handleChange={handleChange}
          />
          {(permissions?.AUTHOR_CONTENT || permissions?.VISUAL_ASSETS) && (
            <AddButtonWithSeparator
              className="mt-1 mb-1"
              onClick={() => openAddNewElement(index + 1)}
            />
          )}
        </React.Fragment>
      );
    },
  );

  return (
    <>
      {(permissions?.AUTHOR_CONTENT || permissions?.VISUAL_ASSETS) && (
        <AddButtonWithSeparator
          className="mt-1 mb-1"
          onClick={() => openAddNewElement(0)}
        />
      )}
      {sectionsList}
      <AddSectionModal
        showModal={templateSelection === showModalId}
        setShowModal={closeModal}
        onClose={closeModal}
        addNewSection={addNewSection}
      />
    </>
  );
};
export default TemplateMainContent;
