import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';

import TemplateMainContent from './Template';
import TemplateSelectionFooter from './TemplateFooter';
import TemplateTypeSelection from '../../+page/RichContentTemplateSelection';
import { PageHeading } from '@components/PageHeading';
import WorkflowOverviewHeader from '@components/WorkflowHeader';
import WorkflowHeading from '@components/WorkflowHeading';
import { toast } from '@components/ToastNotification/ToastManager';

import {
  carouselSection,
  imageTextSection,
  testimonialsSection,
  textImageSection,
  videoSection,
  videoWithTitleAndDescSection,
  videoWithTitleSection,
} from '../../+page/defaultData';
import {
  fetchRichContent,
  moveTaskForReview,
  moveTaskToComplete,
  patchSelectedRichContentData,
  publishRichContent,
  startReview,
} from '@apis/richContent';
import { ParamTypes } from '@models/context';
import { IPermission, ITemplate } from '@models/rich-content';
import { ROLES_PERMISSIONS } from '@constants/permissions';
import { capitalizeFirstLetter } from '@utils/textTransform';
import { RRichContent } from '@redux/slices/richContent';
import { RSpinner } from '@redux/slices/spinner';
import { Mixpanel } from '@utils/mixpanel';

import './style.scss';

const defaultPermissions = {
  AUTHOR_CONTENT: false,
  VISUAL_ASSETS: false,
  REVIEW: false,
};

const TemplateSelection = () => {
  const [permissions, setPermissions] =
    useState<IPermission>(defaultPermissions);
  const { id } = useParams<ParamTypes>();
  const [templateData, setTemplateData] = useState<ITemplate[]>([]);
  const [taskAssigneeData, setTaskAssigneeData] = useState<any>();
  const userPermissions = useSelector((state) => state.currentUser.permissions);
  const { t } = useTranslation();
  const payload = {
    pageNumber: 1,
    pageSize: 20,
    ids: [id],
  };
  const richContentData = useSelector(
    (state) => state.richContent.selectedRichContent,
  );
  const loggedInUserId = useSelector((state) => state.currentUser?.data?.id);
  const history = useHistory();

  const dispatch = useDispatch();

  useEffect(() => {
    if (richContentData && richContentData.taskList && userPermissions) {
      const taskData: any = {};
      const permissionData: any = {};
      richContentData?.taskList?.forEach((original) => {
        const element = { ...original };
        taskData[element.taskType] = element;
        if (element.assignee !== loggedInUserId) return;
        if (element.taskType === 'AUTHOR_CONTENT') {
          if (element.status == 'TO_DO' || element.status == 'IN_PROGRESS')
            permissionData.AUTHOR_CONTENT = true;
        } else if (
          element.taskType === 'REVIEW' ||
          element.taskType === 'REVIEW_VISUAL_ASSET' ||
          element.taskType === 'REVIEW_CONTENT'
        ) {
          permissionData.REVIEW = true;
        } else if (element.taskType === 'VISUAL_ASSETS') {
          if (element.status == 'TO_DO' || element.status == 'IN_PROGRESS')
            permissionData.VISUAL_ASSETS = true;
        }
      });
      if (userPermissions) {
        const createRichContent = userPermissions?.some(
          (permission: any) =>
            permission.name === ROLES_PERMISSIONS.CREATE_RICH_CONTENT,
        );
        const updateRichContent = userPermissions?.some(
          (permission: any) =>
            permission.name === ROLES_PERMISSIONS.UPDATE_RICH_CONTENT,
        );

        if (createRichContent && updateRichContent) {
          permissionData.AUTHOR_CONTENT = true;
          permissionData.VISUAL_ASSETS = true;
          permissionData.REVIEW = true;
        }
      }

      setPermissions({ ...permissionData });
      setTaskAssigneeData(taskData);
    }
  }, [richContentData, userPermissions]);

  useEffect(() => {
    if (
      taskAssigneeData?.REVIEW?.status == 'TO_DO' &&
      process.env.REACT_APP_WORKFLOW == 'ON'
    ) {
      startReview(id, taskAssigneeData['REVIEW']?.id)
        .then(async () => {
          try {
            const response = await fetchRichContent(payload);
            const [richContent] = response.items;
            dispatch(RRichContent.SET_SELECTED(richContent));
          } catch (error) {
            console.error(error);
          }
        })
        .catch((error: any) => {
          const { errors, body } = error;
          if (errors?.length > 0)
            toast.show({
              message: errors?.[0]?.message,
              error: true,
            });
        });
    }
  }, [taskAssigneeData]);

  useEffect(() => {
    if (!richContentData) {
      fetchRichContent(payload)
        .then((response) => {
          const [richContent] = response.items;
          dispatch(RRichContent.SET_SELECTED(richContent));
          setTemplateData(
            structuredClone(richContent.contentList) as DeepWritable<
              typeof richContent.contentList
            >,
          );
        })
        .catch(console.error);
    } else {
      setTemplateData(
        structuredClone(richContentData.contentList) as DeepWritable<
          typeof richContentData.contentList
        >,
      );
    }
  }, []);

  const addNewTemplateType = (type: string) => {
    if (!richContentData) throw new Error('Rich Content is undefined');
    const contentList = [
      {
        ...imageTextSection,
        order: 1,
      },
      {
        ...textImageSection,
        order: 2,
      },
      {
        ...imageTextSection,
        order: 3,
      },
      type === 'textImage'
        ? {
            ...textImageSection,
            order: 4,
          }
        : type === 'mediaCarousel'
        ? {
            ...carouselSection,
            order: 4,
          }
        : type === 'testimonial'
        ? {
            ...testimonialsSection,
            order: 4,
          }
        : type === 'video'
        ? {
            ...videoSection,
            order: 5,
          }
        : type === 'videoWithTitle'
        ? {
            ...videoWithTitleSection,
            order: 6,
          }
        : {
            ...videoWithTitleAndDescSection,
            order: 7,
          },
    ];
    patchSelectedRichContentData({
      richContentId: id,
      data: { contentList },
    }).then((response) => {
      if (!response) return;
      dispatch(RRichContent.SET_SELECTED_CONTENT_LIST(response.contentList));
      setTemplateData(structuredClone(response.contentList));
    });
  };

  const submitTemplateData = async () => {
    // Send mix panel event
    Mixpanel.track('rich_content_operation', {
      eventName: 'Save rich content template data',
      kpi: 'Usage Grows',
      propertyType: 'event_property',
      fieldsUpdated: templateData,
      id: id,
    });

    dispatch(RSpinner.show(true));
    try {
      await patchSelectedRichContentData({
        richContentId: id,
        data: { contentList: templateData },
        updateAttributes: 'AUTHOR_CONTENT',
      });
      const responseFromRichContentUpdate = await patchSelectedRichContentData({
        richContentId: id,
        data: { contentList: templateData },
        updateAttributes: 'VISUAL_ASSETS',
      });
      if (!responseFromRichContentUpdate) return;

      dispatch(
        RRichContent.SET_SELECTED_CONTENT_LIST(structuredClone(templateData)),
      );

      const taskVisualAsset = responseFromRichContentUpdate.taskList.find(
        (x) => x.taskType === 'VISUAL_ASSETS',
      )!;
      const taskAuthorContent = responseFromRichContentUpdate.taskList.find(
        (x) => x.taskType === 'AUTHOR_CONTENT',
      )!;
      if (process.env.REACT_APP_WORKFLOW == 'ON') {
        if (taskAssigneeData?.AUTHOR_CONTENT?.status !== 'COMPLETED') {
          await moveTaskForReview(id, taskAuthorContent.id);
        }
        if (taskAssigneeData?.VISUAL_ASSETS?.status !== 'COMPLETED') {
          await moveTaskForReview(id, taskVisualAsset.id);
        }
      }
      if (taskAssigneeData?.REVIEW?.status === 'COMPLETED') {
        await publishRichContent(id);
      }

      if (process.env.REACT_APP_WORKFLOW == 'OFF') {
        await moveTaskToComplete(id);
      }
      await fetchRichContent({ pageNumber: 1, pageSize: 20, ids: [id] }).then(
        (response) => dispatch(RRichContent.SET_SELECTED(response.items[0])),
      );
      dispatch(RSpinner.show(false));
      history.push(`/rich-contents/${id}`);
    } catch (error: any) {
      const { errors } = error;
      toast.show({
        message: errors?.[0]?.message,
        error: true,
      });
    }
  };

  return (
    <>
      <PageHeading title={capitalizeFirstLetter(t('rich_content.title'))} />
      {richContentData && (
        <div className="RichContentTemplate">
          <WorkflowOverviewHeader
            centerSection={
              <div className="RichContentTemplate__header">
                <WorkflowHeading
                  heading={`${capitalizeFirstLetter(t('rich_content.title'))} ${
                    richContentData?.title
                  }`}
                  subHeading={capitalizeFirstLetter(
                    t('rich_content.polulate_data'),
                  )}
                />
              </div>
            }
            backButtonLink={`/rich-contents/${id}`}
            centered
          />
          {richContentData?.contentList?.length > 0 ? (
            <div className="RichContentTemplate__main">
              <TemplateMainContent
                templateData={templateData}
                setTemplateData={setTemplateData}
                permissions={permissions}
              />
            </div>
          ) : (
            <TemplateTypeSelection addNewTemplateType={addNewTemplateType} />
          )}
          {taskAssigneeData &&
            richContentData?.workFlowStatus &&
            permissions &&
            richContentData?.contentList?.length > 0 && (
              <TemplateSelectionFooter
                id={id}
                permissions={permissions}
                taskAssigneeData={taskAssigneeData}
                submitTemplateData={submitTemplateData}
                status={richContentData.workFlowStatus}
              />
            )}
        </div>
      )}
    </>
  );
};
export default TemplateSelection;
