import { ENDPOINTS } from '@constants/endpoints';
import { clientWithToken } from '.';
import { User } from '@models/user';

export interface UserResponse {
  data?: any | User[]; // TODO remove any
  status?: number;
  message?: string;
}

/**
 *
 * @author Rishabh Jain
 * @returns {Promise<UserResponse>}
 */
export const getUsers = async (): Promise<UserResponse> => {
  try {
    const url = ENDPOINTS.iam.users;
    const response = await clientWithToken.get<{
      body: {
        pageNumber: number;
        totalItems: number;
        items: User[];
      };
    }>(url);
    return {
      data: response.data.body.items,
      status: response.status,
    };
  } catch (errorObj: any) {
    const { errors } = errorObj;
    return {
      message: errors?.[0]?.message,
    };
  }
};

export const getUsersPermissionBased = async (): Promise<UserResponse> => {
  try {
    const url = ENDPOINTS.iam.usersPermissionBased;
    const response = await clientWithToken.get<{ body: { items: unknown[] } }>(
      url,
    );
    return {
      data: response.data.body.items,
      status: response.status,
    };
  } catch (errorObj: any) {
    const { errors } = errorObj;
    return {
      message: errors?.[0]?.message,
    };
  }
};

export const getUser = async (userId: string) => {
  const url = `${ENDPOINTS.iam.users}/${userId}`;
  const response = await clientWithToken.get<{ body: User }>(url);
  return response.data.body;
};

export const getUserPermissions = async (userId: string) => {
  const url = `${ENDPOINTS.iam.users}/${userId}/${ENDPOINTS.iam.permissions}`;
  const { data } = await clientWithToken.get<{
    body: {
      items: {
        id: string;
        name: string;
      }[];
    };
  }>(url);
  return data.body.items;
};

export const getSelectedUsers = async (
  payload: string[],
): Promise<UserResponse> => {
  try {
    const url = ENDPOINTS.iam.findUsers;
    const response = await clientWithToken.post<{ body: unknown }>(
      url,
      payload,
    );
    return {
      data: response.data.body,
      status: response.status,
    };
  } catch (errorObj: any) {
    const { errors } = errorObj;
    return {
      message: errors?.[0]?.message,
    };
  }
};

/**
 *
 * @author Rishabh Jain
 * @returns {Promise<UserResponse>}
 */
export const getRoles = async (): Promise<UserResponse> => {
  try {
    const url = ENDPOINTS.iam.iamRoles;
    const response = await clientWithToken.get<{ id: string; name: string }[]>(
      url,
    );
    return {
      data: response.data,
      status: response.status,
    };
  } catch (errorObj: any) {
    const { errors } = errorObj;
    return {
      message: errors?.[0]?.message,
    };
  }
};

/**
 *
 * @author Rishabh Jain
 * @returns {Promise<UserResponse>}
 */
export const createUser = async (requestBody: any): Promise<UserResponse> => {
  try {
    const url = ENDPOINTS.iam.users;
    const response = await clientWithToken.post<{ body: User; errors: [] }>(
      url,
      requestBody,
    );
    return {
      data: response.data.body,
      status: response.status,
    };
  } catch (errorObj: any) {
    const { errors } = errorObj;
    return {
      message: errors?.[0]?.message,
    };
  }
};

/**
 *
 * @author Rishabh Jain
 * @returns {Promise<UserResponse>}
 */
export const updateUser = async (update: User): Promise<User> => {
  const url = `${ENDPOINTS.iam.users}/${update.id}`;
  const response = await clientWithToken.put<{ body: User }>(url, update);
  return response.data.body;
};

/**
 *
 * @author Rishabh Jain
 * @returns {Promise<UserResponse>}
 */
export const reActivateUser = async (requestBody: any): Promise<User> => {
  const url = `${ENDPOINTS.iam.users}/${requestBody.id}/${ENDPOINTS.iam.activation}`;
  const response = await clientWithToken.post<{ body: User }>(url, requestBody);
  return response.data.body;
};

/**
 *
 * @author Rishabh Jain
 * @returns {Promise<UserResponse>}
 */
export const deActivateUser = async (requestBody: {
  id: string;
}): Promise<UserResponse> => {
  try {
    const url = `${ENDPOINTS.iam.users}/${requestBody.id}/${ENDPOINTS.iam.activation}`;
    const response = await clientWithToken.delete<{ body: User }>(url);
    return {
      data: response.data.body,
      status: response.status,
    };
  } catch (errorObj: any) {
    const { errors } = errorObj;
    return {
      message: errors?.[0]?.message,
    };
  }
};

/**
 *
 * @author Rishabh Jain
 * @returns {Promise<UserResponse>}
 */
export const resendUserMail = async (
  requestBody: any,
): Promise<UserResponse> => {
  try {
    const url = `${ENDPOINTS.iam.users}/${requestBody.id}/${ENDPOINTS.iam.verificationEmail}`;
    const response = await clientWithToken.post<{
      body: 'Sent verification email.';
    }>(url, requestBody);
    return {
      data: response.data.body,
      status: response.status,
    };
  } catch (errorObj: any) {
    const { errors } = errorObj;
    return {
      message: errors?.[0]?.message,
    };
  }
};

export const fetchUserOnboardingStatus = async () => {
  const url = ENDPOINTS.iam.onboardStatus;
  const response = await clientWithToken.get<{
    body: { [key: string]: boolean };
  }>(url);
  return response.data.body;
};

export const updateUserOnboardingStatus = async (target: string) => {
  const url = `${ENDPOINTS.iam.onboardStatus}/${target}`;
  const response = await clientWithToken.patch<{
    body: { [key: string]: boolean };
  }>(url);
  return response.data.body;
};

export const updateRolePermissions = async (
  roleId: string,
  permissions: {
    id: string;
    name: string;
  }[],
) => {
  await clientWithToken.post<unknown>(
    `${ENDPOINTS.iam.roles}/${roleId}/permissions`,
    permissions,
  );
};

export const getRole = async (roleId: string) => {
  const response = await clientWithToken.get<{
    permission: {
      isAllowed: boolean;
      id: string;
      keycloak_permission: string;
    }[];
  }>(`${ENDPOINTS.iam.rolePermissions}/${roleId}`);

  return response.data;
};
