import { Experience } from '@models/experience';
import { ReactComponent as TargetingSvg } from '@assets/icons/experience-targeting.svg';

import './style.scss';

type ColorSet = {
  primary: string;
  background: string;
  secondary: string;
};

export const colorsByExperienceLevel: {
  [key in Experience['level']]: ColorSet;
} = {
  basic: {
    primary: 'var(--emerald)',
    background: 'var(--emerald-shade-9)',
    secondary: 'var(--emerald-shade-7)',
  },
  advanced: {
    primary: 'var(--capri)',
    background: 'var(--capri-shade-10)',
    secondary: 'var(--capri-shade-7)',
  },
  expert: {
    primary: 'var(--bleu-de-france)',
    background: 'var(--bleu-de-france-shade-9)',
    secondary: 'var(--bleu-de-france-shade-7)',
  },
};

const ExperienceTargets = ({ experience }: { experience: Experience }) => {
  const getTargetsClass = (target: string) =>
    target + (experience.attributes.includes(target) ? ' active' : '');

  const activeDescendants = [...experience.attributes];
  if (activeDescendants.some)
    if (activeDescendants.some((att) => att.startsWith('assets_')))
      activeDescendants.push('assets');

  const colorSet = colorsByExperienceLevel[experience.level];

  const cssDefinitions = {
    '--experience-targets-primary-color': colorSet.primary,
    '--experience-targets-secondary-color': colorSet.secondary,
    '--experience-targets-background-color': colorSet.background,
  } as React.CSSProperties;

  return (
    <div className="experience-targets" style={cssDefinitions}>
      <TargetingSvg aria-activedescendant={activeDescendants.join(' ')} />
    </div>
  );
};

export default ExperienceTargets;
