import { useTranslation } from 'react-i18next';

import CreateNewCard from '@components/CreateNewCard';

import { capitalizeFirstLetter } from '@utils/textTransform';

import TextImageTemplate from '@assets/illustrations/TextImageTemplate.png';
import TextImageVideoTemplate from '@assets/illustrations/TextImageVideo.png';
import TextImageTestimonialTemplate from '@assets/illustrations/TextImageTestimonial.png';
// import TextImageCarousalTemplate from '@assets/illustrations/TextImageCarosalTemplate.png';

import './style.scss';

const TemplateTypeSelection: React.FC<any> = ({ addNewTemplateType }) => {
  const { t } = useTranslation();
  const defaultData = [
    {
      image: TextImageTemplate,
      buttonText: `${t('global.template')} 1`,
      subText: t(
        'template.perfect_for_listing_out_multiple_product_features_and_benefits',
      ),
      action: 'textImage',
    },
    // { // Will be enabled in rich content phase 2
    //   image: TextImageCarousalTemplate,
    //   buttonText: `${t('global.template')} ${t('global.2')}`,
    //   subText: t(
    //     'template.ideal_if_imagery_will_help_the_product_features_to_life',
    //   ),
    //   action: 'mediaCarousel',
    // },
    {
      image: TextImageVideoTemplate,
      buttonText: `${t('global.template')} 2`,
      subText: t(
        'template.include_video_content_alongside_product_features_and_benefits',
      ),
      action: 'video',
    },
    {
      image: TextImageTestimonialTemplate,
      buttonText: `${t('global.template')} 3`,
      subText: t(
        'template.optimized_to_showcase_product_features_and_customer_testimonials',
      ),
      action: 'testimonial',
    },
  ];

  return (
    <>
      <div className="RichContentTemplateSelection">
        {defaultData.map(({ image, buttonText, action, subText }, index) => (
          <CreateNewCard
            key={index}
            onButtonClick={() => {
              addNewTemplateType(action);
            }}
            image={image}
            buttonText={buttonText}
            subText={capitalizeFirstLetter(subText)}
          />
        ))}
      </div>
    </>
  );
};
export default TemplateTypeSelection;
