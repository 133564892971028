import React from 'react';

import Card from '../Card';
import PrimaryButton from '../PrimaryButton';

import createNewImage from '@assets/icons/createContextualizedVersion.svg';

import './style.scss';

const CreateNewCard: React.FC<
  {
    image?: string;
    onButtonClick: Function;
    buttonText: string;
    subText?: string;
  } & React.HTMLAttributes<HTMLDivElement>
> = ({
  image = createNewImage,
  onButtonClick,
  buttonText,
  subText = '',
  style,
  className,
}) => {
  return (
    <div onClick={() => onButtonClick()}>
      <Card
        className={`create-new-card ${className || ''}`}
        style={style}
        showShadowOnHover
      >
        <div className="create-new-card__illustration">
          <img src={image} alt="placeholder image" />
        </div>

        <PrimaryButton className="create-new-card__button" label={buttonText} />
        <p className="create-new-card__subText">{subText}</p>
      </Card>
    </div>
  );
};

export default CreateNewCard;
